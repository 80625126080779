import React from 'react';
import {Form} from 'react-bootstrap';
import RequiredSign from "@/components/extra/RequiredSign";
import {execute, formatDate} from "@/utils/common";
import DatePicker from 'react-datepicker';

const FormDateInput = ({register, errors, accessor, title, value, callback, placeholder = '', required = false, disabled = false}) => {
    return (<Form.Group>
        <Form.Label>
            {title}: {required && <RequiredSign/>}
        </Form.Label>
        <DatePicker
            {...register(accessor)}
            disabled={disabled}
            dateFormat={'dd/MM/yyyy'}
            selected={value}
            onChange={(value) => execute(callback, formatDate(value))}
            className={`form-control ${errors[accessor]?.message ? 'is-invalid' : ''}`}
            placeholderText={placeholder}
        />
        <Form.Control.Feedback type="invalid"
                               className={errors[accessor]?.message ? 'block' : 'hidden'}>
            {errors[accessor]?.message}
        </Form.Control.Feedback>
    </Form.Group>);
};

export default FormDateInput;
