import React from 'react';
import LogoutForm from '@/components/authentication/LogoutForm';

import AuthDefaultLayout from '@/layouts/AuthDefaultLayout';

const Logout = () => {
  return (
    <AuthDefaultLayout>
      <div className="text-center">
        <LogoutForm titleTag="h3" />
      </div>
    </AuthDefaultLayout>
  );
};

export default Logout;
