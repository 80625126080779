import { useEffect } from 'react';
import {execute} from "@/utils/common";

const useEnterKeyPress = (callback) => {
    useEffect(() => {
        const handleKeyDown = async (event) => {
            if (event.key === 'Enter') {
                await execute(callback);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [callback]); // Dependency array to re-run effect if onEnterPress changes
};

export default useEnterKeyPress;
