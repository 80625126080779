import { format, parse, parseISO } from "date-fns";
import { saveAs } from "file-saver";
import _ from "lodash";

export const execute = (func, ...args) => {
    return new Promise((resolve, reject) => {
        if (func) {
            if (typeof func === "function") {
                try {
                    const result = func(...args); // Pass the arguments to the function
                    if (result instanceof Promise) {
                        result
                            .then(result => resolve(result))
                            .catch(error => reject(error));
                    } else {
                        resolve(result);
                    }
                } catch (error) {
                    reject(error);
                }
            } else if (func instanceof Promise) {
                func.then(result => resolve(result)).catch(error =>
                    reject(error),
                );
            } else {
                reject(new Error("Not supported."));
            }
        } else {
            resolve(undefined);
        }
    });
};

export const delay = timeout => {
    return new Promise(resolve => setTimeout(resolve, timeout));
};

export const delayExecute =
    timeout =>
    (func, ...args) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                execute(func, ...args)
                    .then(result => resolve(result))
                    .catch(error => reject(error));
            }, timeout);
        });
    };

export const toArray = value => {
    if (value) {
        if (Array.isArray(value)) {
            return value;
        } else {
            return [value];
        }
    }
    return [];
};

export const isNotEmptyArray = obj => {
    return Array.isArray(obj) && obj.length > 0;
};

export const getFirstItemOfArray = items => {
    if (items && Array.isArray(items) && items.length > 0) {
        return items[0];
    }
    return undefined;
};

export const isEmptyOrWhitespace = value => {
    return _.isNil(value) || (_.isString(value) && value.trim().length === 0);
};

export const isValidString = value => {
    return !_.isNil(value) && _.isString(value) && value.trim().length > 0;
};

export const formatDate = value => {
    if (_.isDate(value)) return format(value, "yyyy-MM-dd");
    return "";
};

export const toDate = value => {
    if (_.isDate(value)) return value;
    if (_.isString(value)) return parse(value, "yyyy-MM-dd", new Date());
    return undefined;
};

export const toNumber = value => {
    if (_.isNumber(value)) return value;
    if (_.isString(value)) return _.toNumber(value);
    return 0;
};

export const exportToJsonFile = (jsonData, fileName = "data.json") => {
    const blob = new Blob([JSON.stringify(jsonData, null, 2)], {
        type: "application/json",
    });
    saveAs(blob, fileName);
};

export const merge = (source, target, callback) => {
    const keys = Object.keys(source);
    const ext = {};
    for (const key of keys) {
        ext[key] = callback(key, source[key], target[key]);
    }
    return { ...target, ...source, ...ext };
};

export const mergeStringCallback = (key, sourceValue, targetValue) => {
    return `${targetValue || ""} ${sourceValue || ""}`;
};
