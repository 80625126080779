import { useCallback, useMemo, useState } from "react";

import PropTypes from "prop-types";

import useProcessing from "@/hooks/useProcessing";
import useProtectedHttp from "@/hooks/useProtectedHttp";
import { isNotEmptyArray } from "@/utils/common";

const useLecture = () => {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post } = useProtectedHttp();

    const [lecture, setLecture] = useState(undefined);
    const [lecturesPagination, setLecturesPagination] = useState({});

    //create
    const createLectureProcessing = useMemo(() => {
        return !!processing?.createLecture;
    }, [processing?.createLecture]);

    const createLectureErrorMessage = useMemo(() => {
        return errorMessage?.createLecture;
    }, [errorMessage?.createLecture]);

    const createLecture = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "createLecture",
                async () => {
                    try {
                        if (!inSilentMode) setLecture(undefined);
                        const result = await post(`/v1/lectures/create`, data);
                        setLecture(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    //search
    const searchLectureProcessing = useMemo(() => {
        return !!processing?.searchLecture;
    }, [processing?.searchLecture]);

    const searchLectureErrorMessage = useMemo(() => {
        return errorMessage?.searchLecture;
    }, [errorMessage?.searchLecture]);

    const searchLecture = useCallback(
        (
            data = {},
            options = { page: 0, size: 10, sort: "" },
            inSilentMode = false,
        ) => {
            return process(
                "searchLecture",
                async () => {
                    try {
                        const { page, size, sort } = options || {};
                        if (!inSilentMode) setLecturesPagination({});
                        const result =
                            size > 0
                                ? await post(
                                      `/v1/lectures/search?page=${page}&size=${size}&sort=${sort}`,
                                      data,
                                  )
                                : await get(`/v1/lectures`);
                        setLecturesPagination(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post, get],
    );

    //get
    const getLectureProcessing = useMemo(() => {
        return !!processing?.getLecture;
    }, [processing?.getLecture]);

    const getLectureErrorMessage = useMemo(() => {
        return errorMessage?.getLecture;
    }, [errorMessage?.getLecture]);

    const getLecture = useCallback(
        (id, options = { includes: [] }, inSilentMode = false) => {
            return process(
                "getLecture",
                async () => {
                    try {
                        const { includes } = options || {};
                        if (!inSilentMode) setLecture(undefined);
                        const result = await get(
                            `/v1/lectures/${id}?includes=${(includes || []).join(",")}`,
                        );
                        setLecture(result);

                        return Promise.resolve(result?.lecture);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, get],
    );

    //delete
    const deleteLectureProcessing = useMemo(() => {
        return !!processing?.deleteLecture;
    }, [processing?.deleteLecture]);

    const deleteLectureErrorMessage = useMemo(() => {
        return errorMessage?.deleteLecture;
    }, [errorMessage?.deleteLecture]);

    const deleteLecture = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "deleteLecture",
                async () => {
                    try {
                        if (!inSilentMode) setLecture(undefined);
                        const result = await post(`/v1/lectures/delete`, data);
                        setLecture(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    //update
    const updateLectureProcessing = useMemo(() => {
        return !!processing?.updateLecture;
    }, [processing?.updateLecture]);

    const updateLectureErrorMessage = useMemo(() => {
        return errorMessage?.updateLecture;
    }, [errorMessage?.updateLecture]);

    const updateLecture = useCallback(
        (data, options = { updateFields: [] }, inSilentMode = false) => {
            return process(
                "updateLecture",
                async () => {
                    try {
                        const { id } = data || {};
                        const { updateFields } = options || {};
                        const result = isNotEmptyArray(updateFields)
                            ? await post(`/v1/lectures/${id}/patch`, {
                                  data,
                                  updateFields,
                              })
                            : await post(`/v1/lectures/${id}/update`, data);
                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    return {
        createLectureProcessing,
        createLectureErrorMessage,
        createLecture,
        searchLectureProcessing,
        searchLectureErrorMessage,
        searchLecture,
        lecturesPagination,
        getLectureProcessing,
        getLectureErrorMessage,
        getLecture,
        lecture,
        deleteLectureProcessing,
        deleteLectureErrorMessage,
        deleteLecture,
        updateLectureProcessing,
        updateLectureErrorMessage,
        updateLecture,
    };
};

useLecture.propTypes = {
    processing: PropTypes.shape({
        createLecture: PropTypes.bool,
        searchLecture: PropTypes.bool,
        getLecture: PropTypes.bool,
        deleteLecture: PropTypes.bool,
        updateLecture: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        createLecture: PropTypes.string,
        searchLecture: PropTypes.string,
        getLecture: PropTypes.string,
        deleteLecture: PropTypes.string,
        updateLecture: PropTypes.bool,
    }),
};

export default useLecture;
