import {useCallback, useMemo, useState} from 'react';

import useProtectedHttp from "@/hooks/useProtectedHttp";
import useProcessing from "@/hooks/useProcessing";
import PropTypes from "prop-types";
import {isNotEmptyArray} from "@/utils/common";

const useQuiz = () => {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post } = useProtectedHttp();

    const [quiz, setQuiz] = useState(undefined);
    const [quizzesPagination, setQuizzesPagination] = useState({});


    //create
    const createQuizProcessing = useMemo(() => {
        return !!processing?.createQuiz;
    }, [processing?.createQuiz]);

    const createQuizErrorMessage = useMemo(() => {
        return errorMessage?.createQuiz;
    }, [errorMessage?.createQuiz]);

    const createQuiz = useCallback((data, inSilentMode = false) => {
        return process('createQuiz', async () => {
            try {
                if(!inSilentMode) setQuiz(undefined);
                const result = await post(`/v1/quizzes/create`, data);
                setQuiz(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);


    //search
    const searchQuizProcessing = useMemo(() => {
        return !!processing?.searchQuiz;
    }, [processing?.searchQuiz]);

    const searchQuizErrorMessage = useMemo(() => {
        return errorMessage?.searchQuiz;
    }, [errorMessage?.searchQuiz]);

    const searchQuiz = useCallback( (data = {}, options = {page: 0, size: 10, sort: ''}, inSilentMode = false) => {
        return process('searchQuiz', async () => {
            try {
                const {page, size, sort} = options || {};
                if(!inSilentMode) setQuizzesPagination({});
                const result = size > 0 ? await post(`/v1/quizzes/search?page=${page}&size=${size}&sort=${sort}`, data)
                    : await get(`/v1/quizzes`);
                setQuizzesPagination(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post, get]);


    //get
    const getQuizProcessing = useMemo(() => {
        return !!processing?.getQuiz;
    }, [processing?.getQuiz]);

    const getQuizErrorMessage = useMemo(() => {
        return errorMessage?.getQuiz;
    }, [errorMessage?.getQuiz]);

    const getQuiz = useCallback((id, options = {includes: []}, inSilentMode = false) => {
        return process('getQuiz', async () => {
            try {
                const {includes} = options || {};
                if(!inSilentMode) setQuiz(undefined);
                const result = await get(`/v1/quizzes/${id}?includes=${(includes || []).join(',')}`);
                console.log('getQuiz', result);
                setQuiz(result);

                return Promise.resolve(result?.quiz);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, get]);


    //delete
    const deleteQuizProcessing = useMemo(() => {
        return !!processing?.deleteQuiz;
    }, [processing?.deleteQuiz]);

    const deleteQuizErrorMessage = useMemo(() => {
        return errorMessage?.deleteQuiz;
    }, [errorMessage?.deleteQuiz]);

    const deleteQuiz = useCallback((data, inSilentMode = false) => {
        return process('deleteQuiz', async () => {
            try {
                if(!inSilentMode) setQuiz(undefined);
                const result = await post(`/v1/quizzes/delete`, data);
                setQuiz(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);


    //update
    const updateQuizProcessing = useMemo(() => {
        return !!processing?.updateQuiz;
    }, [processing?.updateQuiz]);

    const updateQuizErrorMessage = useMemo(() => {
        return errorMessage?.updateQuiz;
    }, [errorMessage?.updateQuiz]);

    const updateQuiz = useCallback((data, options = {updateFields: []}, inSilentMode = false) => {
        return process('updateQuiz', async () => {
            try {
                const {id} = data || {};
                const {updateFields} = options || {};
                const result = isNotEmptyArray(updateFields) ? await post(`/v1/quizzes/${id}/patch`, {data, updateFields}) : await post(`/v1/quizzes/${id}/update`, data);
                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);

    return {
        createQuizProcessing, createQuizErrorMessage, createQuiz,
        searchQuizProcessing, searchQuizErrorMessage, searchQuiz, quizzesPagination,
        getQuizProcessing, getQuizErrorMessage, getQuiz, quiz,
        deleteQuizProcessing, deleteQuizErrorMessage, deleteQuiz,
        updateQuizProcessing, updateQuizErrorMessage, updateQuiz,
    };
};

useQuiz.propTypes = {
    processing: PropTypes.shape({
        createQuiz: PropTypes.bool,
        searchQuiz: PropTypes.bool,
        getQuiz: PropTypes.bool,
        deleteQuiz: PropTypes.bool,
        updateQuiz: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        createQuiz: PropTypes.string,
        searchQuiz: PropTypes.string,
        getQuiz: PropTypes.string,
        deleteQuiz: PropTypes.string,
        updateQuiz: PropTypes.bool,
    }),
};

export default useQuiz;
