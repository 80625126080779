import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {execute} from "@/utils/common";
import useEnterKeyPress from "@/hooks/useEnterKeyPress";
import FalconCloseButton from "@/components/common/FalconCloseButton";
import IconButton from "@/components/common/IconButton";
import {FiRefreshCw} from "react-icons/fi";

const FormDialog = forwardRef((props, ref) => {
    const {
        title = '',
        cancelButtonLabel = 'No',
        submitButtonLabel = 'Yes',
        cancelButtonIcon = 'ban',
        submitButtonIcon = 'check',
        cancelButtonVariant = 'secondary',
        submitButtonVariant = 'primary',
        onCancel,
        onSubmit,
        onClose,
        size = 'md',
        children
    } = props;

    const callbackRef = useRef(null);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const showDialog = useCallback(() => {
        setShow(true)
    }, [])

    const hideDialog = useCallback(() => {
        setShow(false)
    }, [])

    const handleCancel = useCallback(async (onCancel, onClose) => {
        await execute(onCancel);
        hideDialog();
        await execute(onClose);
    }, [hideDialog])

    const handleSubmit = useCallback(async (onSubmit, onClose) => {
        setLoading(true);
        const {result, error} = (await execute(onSubmit)) || {};

        if (result && !error) {
            hideDialog();
            await execute(onClose, result);
        }
        setLoading(false);
    }, [hideDialog]);

    useEnterKeyPress(async () => {
        await execute(callbackRef.current);
    })

    useEffect(() => {
        callbackRef.current = show ? () => handleSubmit(onSubmit, onClose) : undefined;
    }, [handleSubmit, onClose, onSubmit, show]);

    useImperativeHandle(ref, () => ({
        showDialog: showDialog,
        hideDialog: hideDialog,
    }), [showDialog, hideDialog]);

    return (
        <>
            {show && (
                <Modal show={show} onHide={() => handleCancel(onCancel, onClose)} keyboard={false} backdrop="static" centered={true} size={size}>
                    <Modal.Header>
                        <Modal.Title>{title}</Modal.Title>
                        <FalconCloseButton onClick={() => handleCancel(onCancel, onClose)}/>
                    </Modal.Header>
                    <Modal.Body>
                        {children}
                    </Modal.Body>
                    {onSubmit && (
                        <Modal.Footer>
                            <IconButton
                                className={'mx-2'}
                                variant={cancelButtonVariant}
                                icon={cancelButtonIcon}
                                transform="shrink-3"
                                onClick={() => handleCancel(onCancel, onClose)}
                            >
                                <span className="d-none d-sm-inline-block ms-1">{cancelButtonLabel}</span>
                            </IconButton>

                            <IconButton
                                className={'mx-2'}
                                variant={submitButtonVariant}
                                icon={submitButtonIcon}
                                transform="shrink-3"
                                onClick={() => handleSubmit(onSubmit, onClose)}
                            >
                    <span className="d-none d-sm-inline-block ms-1">
                        {loading && <FiRefreshCw className={'me-1 spin'}/>}
                        {submitButtonLabel}
                    </span>
                            </IconButton>
                        </Modal.Footer>
                    )}
                </Modal>
            )}
        </>
    );
});

export default FormDialog;
