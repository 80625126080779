import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAds from "@/hooks/biz/useAds";
import usePostCallback from "@/hooks/usePostCallback";
import FormErrorMessage from "@/components/shared/FormErrorMessage";
import FormTextInput from "@/components/shared/FormTextInput";
import { spaces } from "@/components/app/ads/AdsManagement";
import FormSelectInput from "@/components/shared/FormSelectInput";

const DeleteAdsForm = forwardRef((props, ref) => {
    const { data } = props || {};

    const validationSchema = yup.object().shape({
        id: yup.number().moreThan(0, "Quảng cáo không hợp lệ"),
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...data,
        },
    });

    const {
        setValue,
        register,
        formState: { errors },
        trigger,
        getValues,
    } = methods;

    const handleValueChange = useCallback(
        accessor => value => {
            setValue(accessor, value, { shouldValidate: true });
            // await trigger();
        },
        [setValue],
    );

    const { deleteAdsProcessing, deleteAdsErrorMessage, deleteAds } = useAds();

    const disabled = useMemo(() => {
        return true;
    }, []);

    const errorMessage = useMemo(() => {
        return deleteAdsErrorMessage;
    }, [deleteAdsErrorMessage]);

    const onSubmit = useCallback(() => {
        const hasError = Object.keys(errors).length > 0;
        if (!hasError) {
            const values = getValues() || {};
            return deleteAds([values["id"]]);
        } else {
            return { error: errors };
        }
    }, [deleteAds, errors, getValues]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
        }),
        [onSubmit],
    );

    usePostCallback(trigger);

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Row className="g-3">
                    <Col lg={12}>
                        <FormErrorMessage errorMessage={errorMessage} />
                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"title"}
                            title={"Tiêu đề"}
                            value={getValues("title")}
                            callback={handleValueChange("title")}
                            placeholder={"Nhập Tiêu đề"}
                            required={true}
                            disabled={disabled}
                        />
                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"imageUrl"}
                            title={"Ảnh bìa"}
                            value={getValues("imageUrl")}
                            callback={handleValueChange("imageUrl")}
                            placeholder={"Tải/Chọn Ảnh bìa"}
                            required={true}
                            disabled={disabled}
                        />
                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"contentUrl"}
                            title={"Ảnh nội dung"}
                            value={getValues("contentUrl")}
                            callback={handleValueChange("contentUrl")}
                            placeholder={"Tải/Chọn Ảnh nội dung"}
                            required={true}
                            disabled={disabled}
                        />
                        <FormSelectInput
                            register={register}
                            errors={errors}
                            accessor={"places"}
                            title={"Vị trí"}
                            value={getValues("places")}
                            nonValue={""}
                            defaultValue={""}
                            refData={spaces}
                            refAccessor={"name"}
                            callback={handleValueChange("places")}
                            placeholder={"Vị trí"}
                            required={false}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
});

export default DeleteAdsForm;
