/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';

export const AdvanceTablePagination = ({
                                           canPreviousPage,
                                           canNextPage,
                                           previousPage,
                                           nextPage,
                                           pageCount,
                                           pageIndex,
                                           gotoPage
                                       }) => {
    return (
        <Flex alignItems="center" justifyContent="center">
            <Button
                size="sm"
                variant="falcon-default"
                onClick={(e) => {
                    e.preventDefault();
                    previousPage();
                }}
                className={classNames({ disabled: !canPreviousPage })}
            >
                <FontAwesomeIcon icon="chevron-left" />
            </Button>
            <ul className="pagination mb-0 mx-2">
                {
                    React.Children.toArray(Array.from(Array(pageCount).keys()).map((page, index) => {
                        const defaultElement = (
                            <li key={page} className={classNames({ active: pageIndex === page })}>
                                <Button
                                    size="sm"
                                    variant="falcon-default"
                                    className={classNames('page', {
                                        'me-2': index + 1 !== pageCount
                                    })}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        gotoPage(page);
                                    }}
                                >
                                    {page + 1}
                                </Button>
                            </li>
                        );
                        const dotElement = (
                            <li key={0} className={classNames({ active: pageIndex === page })}>
                                <Button
                                    size="sm"
                                    variant="falcon-default"
                                    className={classNames('page', {
                                        'me-2': true
                                    })}
                                    // onClick={() => gotoPage(page)}
                                >
                                    {'...'}
                                </Button>
                            </li>
                        );

                        const dotNumber = 3;
                        if(pageCount < 10) return <>{defaultElement}</>;

                        if(pageIndex > dotNumber - 1 && index === 0){
                            return <>
                                {defaultElement}
                                {dotElement}
                            </>
                        }


                        if(pageIndex < pageCount - dotNumber && index === pageCount - 1){
                            return <>
                                {dotElement}
                                {defaultElement}
                            </>
                        }
                        if((pageIndex === dotNumber - 1 && index === 0)){
                            return <>{defaultElement}</>
                        }
                        if(
                            (pageIndex === dotNumber - 1 && index === 0) ||
                            (pageIndex - (pageIndex >= pageCount - 1 ? dotNumber - 1 : 1) <= index && index <= pageIndex + (pageIndex < 1 ? dotNumber -1 : 1)) ||
                            (pageIndex === pageCount - dotNumber && index === pageCount - 1)
                        ) {
                            return <>{defaultElement}</>;
                        }

                        return <></>;
                    }))
                }
            </ul>
            <Button
                size="sm"
                variant="falcon-default"
                onClick={(e) => {
                    e.preventDefault();
                    nextPage();
                }}
                className={classNames({ disabled: !canNextPage })}
            >
                <FontAwesomeIcon icon="chevron-right" />
            </Button>
        </Flex>
    );
};

export default AdvanceTablePagination;
