import React, { useMemo, useReducer, useRef } from "react";

import _ from "lodash";

import { AppStateContext } from "@/hooks/useAppState";
import useStorage from "@/hooks/useStorage";

export const AppStateProvider = ({ children }) => {
    const sharedRef = useRef({});
    const { getItem, setItem } = useStorage();
    const initialState = useMemo(() => {
        return Object.freeze({ auth: { jwt: getItem("auth.jwt") } });
    }, [getItem]);

    const [state, dispatch] = useReducer(
        (state, action) => {
            if (action.payload) {
                if (action.store && _.isString(action.store)) {
                    setItem(action.store, _.get(action.payload, action.store));
                }
                return Object.freeze(
                    _.mergeWith(
                        {},
                        state,
                        action.payload,
                        (objValue, srcValue) => {
                            return srcValue; // Replace existing value with the source value
                        },
                    ),
                );
            } else {
                return state;
            }
        },
        initialState,
        initialState => ({ ...initialState }),
    );

    return (
        <AppStateContext.Provider value={{ state, dispatch, sharedRef }}>
            {children}
        </AppStateContext.Provider>
    );
};
