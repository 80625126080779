import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from '@/assets/images/team/3.jpg';
import Avatar from '@/components/common/Avatar';
import useAuth from "@/hooks/useAuth";

const ProfileDropdown = () => {
    const {
        logout
    } = useAuth();

    const handleLogoutClick = (event) => {
        event.preventDefault();
        (async () => logout())();
    };


    return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={team3} preview={false} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">

          {/*<Dropdown.Item href="#!">Set status</Dropdown.Item>*/}
          {/*<Dropdown.Item as={Link} to="/user/profile">*/}
          {/*  Profile &amp; account*/}
          {/*</Dropdown.Item>*/}
          {/*<Dropdown.Item href="#!">Feedback</Dropdown.Item>*/}
          {/*<Dropdown.Divider />*/}
          {/*<Dropdown.Item as={Link} to="/user/settings">*/}
          {/*  Settings*/}
          {/*</Dropdown.Item>*/}
          <Dropdown.Item onClick={handleLogoutClick}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
