import { useCallback, useMemo, useState } from "react";

import PropTypes from "prop-types";

import useProcessing from "@/hooks/useProcessing";
import useProtectedHttp from "@/hooks/useProtectedHttp";

const useLectureQuiz = ({ lectureId }) => {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post } = useProtectedHttp();

    const [quizzesOfLecture, setQuizzesOfLecture] = useState([]);
    //create
    const createLectureQuizProcessing = useMemo(() => {
        return !!processing?.createLectureQuiz;
    }, [processing?.createLectureQuiz]);

    const createLectureQuizErrorMessage = useMemo(() => {
        return errorMessage?.createLectureQuiz;
    }, [errorMessage?.createLectureQuiz]);

    const createLectureQuiz = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "createLectureQuiz",
                async () => {
                    try {
                        const result = await post(
                            `/v1/lectures/${lectureId}/quizzes/create`,
                            data,
                        );

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post, lectureId],
    );

    //delete
    const deleteLectureQuizProcessing = useMemo(() => {
        return !!processing?.deleteLectureQuiz;
    }, [processing?.deleteLectureQuiz]);

    const deleteLectureQuizErrorMessage = useMemo(() => {
        return errorMessage?.deleteLectureQuiz;
    }, [errorMessage?.deleteLectureQuiz]);

    const deleteLectureQuiz = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "deleteLectureQuiz",
                async () => {
                    try {
                        const result = await post(
                            `/v1/lectures/${lectureId}/quizzes/delete`,
                            data,
                        );
                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post, lectureId],
    );

    const getQuizzesOfLectureProcessing = useMemo(() => {
        return !!processing?.getQuizzesOfLecture;
    }, [processing?.getQuizzesOfLecture]);

    const getQuizzesOfLectureErrorMessage = useMemo(() => {
        return errorMessage?.getQuizzesOfLecture;
    }, [errorMessage?.getQuizzesOfLecture]);

    const getQuizzesOfLecture = useCallback(
        ({ options = { includes: ["question"] }, inSilentMode = false }) => {
            const { includes } = options || {};

            return process(
                "getQuizzesOfLecture",
                async () => {
                    try {
                        if (!inSilentMode) setQuizzesOfLecture([]);
                        const result = await get(
                            `/v1/lectures/${lectureId}/quizzes?includes=${(includes || []).join(",")}`,
                        );

                        setQuizzesOfLecture(result);
                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, get, lectureId],
    );

    return {
        createLectureQuizProcessing,
        createLectureQuizErrorMessage,
        createLectureQuiz,

        deleteLectureQuizProcessing,
        deleteLectureQuizErrorMessage,
        deleteLectureQuiz,

        getQuizzesOfLectureProcessing,
        getQuizzesOfLectureErrorMessage,
        getQuizzesOfLecture,
        quizzesOfLecture,
    };
};

useLectureQuiz.propTypes = {
    processing: PropTypes.shape({
        createLectureQuiz: PropTypes.bool,
        deleteLectureQuiz: PropTypes.bool,
        getQuizzesOfLecture: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        createLectureQuiz: PropTypes.string,
        deleteLectureQuiz: PropTypes.string,
        getQuizzesOfLecture: PropTypes.bool,
    }),
};

export default useLectureQuiz;
