import React, {forwardRef, useCallback, useImperativeHandle, useMemo} from 'react';
import {Col, Row} from 'react-bootstrap';
import useCourse from "@/hooks/biz/useCourse";
import FormErrorMessage from "@/components/shared/FormErrorMessage";

const BulkDeleteCourseForm = forwardRef((props, ref) => {
    const selectedData = useMemo(() => {
        return props?.selectedData || [];
    }, [props?.selectedData])

    const {
        deleteCourseProcessing, deleteCourseErrorMessage, deleteCourse,
    } = useCourse();

    const errorMessage = useMemo(() => {
        return deleteCourseErrorMessage;
    }, [deleteCourseErrorMessage])


    const onSubmit = useCallback(() => {
        if (selectedData.length > 0) {
            return deleteCourse(selectedData?.map(item => item.id) || []);
        }
    }, [selectedData, deleteCourse]);

    useImperativeHandle(ref, () => ({
        submit: onSubmit,
    }), [onSubmit]);


    return (
        <Row className="g-3">
            <Col lg={12}>
                <FormErrorMessage errorMessage={errorMessage}/>
                <span>{`Bạn có chắc chắn muốn xoá ${selectedData?.length || 0} Khoá học đã chọn không?`}</span>
            </Col>
        </Row>
    );
});

export default BulkDeleteCourseForm;
