import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import _ from "lodash";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

import QuizSelection from "@/components/app/quizzes/QuizSelection";
import { quizTypes } from "@/components/app/quizzes/utils";
import AdvanceTable from "@/components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "@/components/common/advance-table/AdvanceTableWrapper";
import Loading from "@/components/extra/Loading";
import FormErrorMessage from "@/components/shared/FormErrorMessage";
import {
    buildActionBarColumn,
    buildIdColumn,
    buildRefColumn,
    buildTextColumn,
} from "@/components/shared/TableBuilder";
import useLecture from "@/hooks/biz/useLecture";
import useLectureQuiz from "@/hooks/biz/useLectureQuiz";
import useDebounce from "@/hooks/useDebounce";
import usePostCallback from "@/hooks/usePostCallback";
import { execute, toArray } from "@/utils/common";

const actions = [
    { code: "remove", name: "Gỡ", variant: "danger", icon: "trash" },
];

const buildTableColumns = ({
    data = [],
    selectedData = [],
    refData = { quizTypes: [] },
    callback,
    loading,
}) => {
    return [
        buildIdColumn({
            accessor: "id",
            data: data,
            selectedData: selectedData,
            callback: callback,
        }),
        buildTextColumn({ accessor: "name", title: "Tên Bài kiểm tra" }),
        buildRefColumn({
            accessor: "quizType",
            title: "Loại Bài kiểm tra",
            refData: refData?.quizTypes,
            refAccessor: "name",
        }),
        buildTextColumn({
            accessor: "questionNumber",
            title: "Số lượng câu hỏi",
        }),
        buildActionBarColumn({
            actions: actions,
            callback: callback,
            loading: loading,
        }),
    ];
};

const TableHeader = ({ title, selectedData = [], callback, loading }) => {
    const callbackRef = useRef(null);
    const [keyword, setKeyword] = useState("");
    const debouncedKeyword = useDebounce(keyword, 200);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        if (_.isString(debouncedKeyword))
            (async () =>
                execute(callbackRef.current, {
                    action: "search",
                    payload: debouncedKeyword,
                }))();
    }, [debouncedKeyword]);

    const checkedCount = useMemo(() => {
        return selectedData?.length || 0;
    }, [selectedData]);

    return (
        <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">{title}</h5>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                {checkedCount > 0 && (
                    <div className="d-flex">
                        <Button
                            type="button"
                            variant="danger"
                            size="sm"
                            className="ms-2"
                            disabled={loading}
                            onClick={e => {
                                e.preventDefault();
                                return execute(callback, {
                                    action: "bulk_remove",
                                    payload: selectedData || [],
                                });
                            }}
                        >
                            {`Gỡ (${checkedCount})`}
                        </Button>
                    </div>
                )}
                {loading && <Loading size={16} />}
            </Col>
        </Row>
    );
};

const QuizOfLectureManagement = ({ className }) => {
    const { id } = useParams();

    const {
        getLectureProcessing,
        getLectureErrorMessage,
        getLecture,
        lecture,
    } = useLecture();

    const {
        createLectureQuizProcessing,
        createLectureQuizErrorMessage,
        createLectureQuiz,
        deleteLectureQuizProcessing,
        deleteLectureQuizErrorMessage,
        deleteLectureQuiz,
        getQuizzesOfLectureProcessing,
        getQuizzesOfLectureErrorMessage,
        getQuizzesOfLecture,
        quizzesOfLecture,
    } = useLectureQuiz({ lectureId: id });

    const processing = useMemo(() => {
        return (
            createLectureQuizProcessing ||
            deleteLectureQuizProcessing ||
            getQuizzesOfLectureProcessing
        );
    }, [
        createLectureQuizProcessing,
        deleteLectureQuizProcessing,
        getQuizzesOfLectureProcessing,
    ]);

    const errorMessage = useMemo(() => {
        return (
            createLectureQuizErrorMessage ||
            deleteLectureQuizErrorMessage ||
            getQuizzesOfLectureErrorMessage
        );
    }, [
        createLectureQuizErrorMessage,
        deleteLectureQuizErrorMessage,
        getQuizzesOfLectureErrorMessage,
    ]);

    const notBeUsedIds = useMemo(() => {
        return quizzesOfLecture.map(item => item.id);
    }, [quizzesOfLecture]);

    const data = useMemo(() => {
        return quizzesOfLecture.map(item => item.quiz);
    }, [quizzesOfLecture]);

    const [selectedData, setSelectedData] = useState([]);

    const addSelectedData = useCallback((...items) => {
        const ids = items?.map(item => item.id) || [];
        setSelectedData(prevState => {
            return [
                ...prevState.filter(item => !ids.includes(item.id)),
                ...Object.freeze(items || []),
            ];
        });
    }, []);

    const removeSelectedData = useCallback((...items) => {
        const ids = items?.map(item => item.id);
        setSelectedData(prevState => {
            return [...prevState.filter(item => !ids.includes(item.id))];
        });
    }, []);

    const addAllSelectedData = useCallback(() => {
        setSelectedData(prevState => Object.freeze(data || []));
    }, [data]);

    const clearAllSelectedData = useCallback(() => {
        setSelectedData(prevState => []);
    }, []);

    const fetchQuizzesOfLecture = useCallback(() => {
        return getQuizzesOfLecture({ inSilentMode: true });
    }, [getQuizzesOfLecture]);

    const fetchLecture = useCallback(() => {
        return getLecture(id);
    }, [getLecture, id]);

    useEffect(() => {
        setSelectedData(prevState => []);
    }, [data]);

    const handleCallback = useCallback(
        ({ action, payload }) => {
            switch (action) {
                case "check":
                    return addSelectedData(payload);
                case "checkAll":
                    return addAllSelectedData();
                case "uncheck":
                    return removeSelectedData(payload);
                case "uncheckAll":
                    return clearAllSelectedData();
                case "add":
                case "bulk_add":
                    createLectureQuiz(
                        toArray(payload).map(item => ({
                            quizId: item.id,
                            retryable: 0,
                        })),
                    ).then(result => {
                        return fetchQuizzesOfLecture();
                    });
                    break;
                case "remove":
                case "bulk_remove":
                    deleteLectureQuiz(
                        toArray(payload).map(item => item.id),
                    ).then(result => {
                        return fetchQuizzesOfLecture();
                    });
                    break;
                default:
                    break;
            }
        },
        [
            addAllSelectedData,
            addSelectedData,
            clearAllSelectedData,
            createLectureQuiz,
            deleteLectureQuiz,
            fetchQuizzesOfLecture,
            removeSelectedData,
        ],
    );

    const columns = useMemo(() => {
        return buildTableColumns({
            data: data,
            selectedData: selectedData,
            refData: { quizTypes: quizTypes },
            callback: handleCallback,
            loading: processing,
        });
    }, [data, selectedData, handleCallback, processing]);

    usePostCallback(fetchLecture, fetchQuizzesOfLecture);

    return (
        <Col className="flex flex-col">
            {!!errorMessage && (
                <Col className="d-flex justify-content-center my-2">
                    <FormErrorMessage errorMessage={errorMessage} />
                </Col>
            )}
            <Card className={`h-100 ${className}`}>
                <Card.Header className="border-bottom border-200">
                    <TableHeader
                        title={`Bài kiểm tra - Bài giảng: ${lecture?.title || "N/A"}`}
                        selectedData={selectedData}
                        callback={handleCallback}
                        loading={processing}
                    />
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTableWrapper
                        columns={columns}
                        data={data || []}
                        sortable={false}
                        pagination={false}
                        selection={false}
                        perPage={0}
                    >
                        <AdvanceTable
                            table
                            headerClassName="bg-body-tertiary text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                                striped: true,
                                className: "fs-11 mb-0 overflow-hidden",
                            }}
                        />
                    </AdvanceTableWrapper>
                </Card.Body>
            </Card>
            <QuizSelection
                notBeUsedIds={notBeUsedIds}
                className={"mt-2"}
                callback={handleCallback}
            />
        </Col>
    );
};

export default QuizOfLectureManagement;
