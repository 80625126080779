import React from "react";

import { format } from "date-fns";
import _ from "lodash";
import Form from "react-bootstrap/Form";

import NoImageAvailable from "@/assets/images/live/no-image-available.jpg";
import Avatar from "@/components/common/Avatar";
import Flex from "@/components/common/Flex";
import IconButton from "@/components/common/IconButton";
import YoutubeThumbnail from "@/components/common/YoutubeThumbnail";
import { execute } from "@/utils/common";

const formatText = value => {
    if (value) return value;
    else return "-";
};

const formatDate = value => {
    if (value) return format(value, "dd/MM/yyyy");
    else return "-";
};

export const buildIdColumn = ({
    accessor,
    data,
    selectedData,
    callback,
    notBeUsedIds = [],
}) => {
    return {
        accessor: accessor,
        Header: () => {
            const checked =
                selectedData.length > 0 && selectedData.length === data.length;
            const indeterminate =
                selectedData.length > 0 && selectedData.length !== data.length;
            return (
                <Form.Check
                    type={"checkbox"}
                    checked={checked}
                    indeterminate={indeterminate}
                    className={"form-check mb-0 d-flex align-items-center"}
                    onChange={() => {
                        return execute(callback, {
                            action:
                                indeterminate || checked
                                    ? "uncheckAll"
                                    : "checkAll",
                        });
                    }}
                />
            );
        },
        headerProps: {
            style: {
                width: "50px",
            },
        },
        disableSortBy: true,
        cellProps: {
            className: "text-start py-2 pe-2",
        },

        Cell: rowData => {
            const { id } = rowData.row.original;
            const checked = selectedData.some(item => item.id === id);
            const notBeUsed = _.includes(notBeUsedIds, id);
            return (
                <Form.Check
                    type={"checkbox"}
                    checked={notBeUsed ? false : checked}
                    disabled={notBeUsed}
                    onChange={e => {
                        return execute(callback, {
                            action: e.target.checked ? "check" : "uncheck",
                            payload: rowData.row.original,
                        });
                    }}
                    className={"form-check mb-0 d-flex align-items-center"}
                />
            );
        },
    };
};

export const defaultActions = [
    { code: "edit", name: "Sửa", variant: "falcon-default", icon: "edit" },
    { code: "delete", name: "Xoá", variant: "danger", icon: "trash" },
];

export const buildActionBarColumn = ({
    actions = defaultActions,
    callback,
    loading,
    notBeUsedIds = [],
}) => {
    return {
        accessor: "none",
        Header: "",
        disableSortBy: true,
        cellProps: {
            className: "text-end py-2 pe-2",
        },
        Cell: rowData => {
            const { id } = rowData.row.original;
            const notBeUsed = _.includes(notBeUsedIds, id);
            return (
                <div className="flex justify-between items-center flex-nowrap">
                    {actions?.map((action, index) => (
                        <IconButton
                            key={index}
                            className={"mx-2"}
                            variant={action?.variant}
                            size="sm"
                            icon={action?.icon}
                            transform="shrink-3"
                            disabled={notBeUsed || loading}
                            onClick={e => {
                                e.preventDefault();
                                (async () =>
                                    execute(callback, {
                                        action: action?.code,
                                        payload: rowData.row.original,
                                    }))();
                            }}
                        >
                            <span className="d-none d-sm-inline-block ms-1">
                                {action?.name}
                            </span>
                        </IconButton>
                    ))}
                </div>
            );
        },
    };
};

export const buildTextColumn = ({ accessor, title }) => {
    return {
        accessor: accessor,
        Header: title,
        headerProps: { className: "text-900" },
        disableSortBy: true,
        cellProps: {
            className: "py-2 pe-2 fw-semibold mb-0",
        },
    };
};

export const buildDateColumn = ({ accessor, title }) => {
    return {
        accessor: accessor,
        Header: title,
        headerProps: { className: "text-900" },
        disableSortBy: true,
        cellProps: {
            className: "py-2 pe-2",
        },
        Cell: rowData => {
            return (
                <span className="fw-semibold mb-0 d-flex align-items-center">
                    {formatDate(rowData.row.original[accessor])}
                </span>
            );
        },
    };
};

export const buildRefColumn = ({ accessor, title, refData, refAccessor }) => {
    return {
        accessor: accessor,
        Header: title,
        headerProps: { className: "text-900" },
        disableSortBy: true,
        cellProps: {
            className: "py-2 pe-2",
        },
        Cell: rowData => {
            const refItem = refData?.find(
                item => item.id === rowData.row.original[accessor],
            );
            return (
                <span className="fw-semibold mb-0 d-flex align-items-center">
                    {refItem ? refItem[refAccessor] : "-"}
                </span>
            );
        },
    };
};

export const buildImageColumn = ({ accessor, title, previewTitleAccessor }) => {
    return {
        accessor: accessor,
        Header: title,
        headerProps: { className: "text-900" },
        disableSortBy: true,
        cellProps: {
            className: "py-2 pe-2",
        },
        Cell: rowData => {
            const src = rowData.row.original[accessor];
            const previewTitle = rowData.row.original[previewTitleAccessor];
            return (
                <Flex alignItems="center">
                    <Avatar
                        name={title}
                        size={"2xl"}
                        rounded={"3"}
                        alt={title}
                        src={src || NoImageAvailable}
                        preview={!!src}
                        previewTitle={previewTitle || title}
                        mediaClass={`text-primary bg-primary-subtle fs-9`}
                    />
                </Flex>
            );
        },
    };
};

export const buildYoutubeThumbnailColumn = ({
    accessor,
    title,
    previewTitleAccessor,
}) => {
    return {
        accessor: accessor,
        Header: title,
        headerProps: { className: "text-900" },
        disableSortBy: true,
        cellProps: {
            className: "py-2 pe-2",
        },
        Cell: rowData => {
            const src = rowData.row.original[accessor];
            const previewTitle = rowData.row.original[previewTitleAccessor];
            return (
                <Flex alignItems="center">
                    <YoutubeThumbnail
                        name={title}
                        rounded={"3"}
                        alt={title}
                        src={src}
                        size={"2xl"}
                        style={{
                            width: "4.5rem",
                        }}
                        preview={!!src}
                        previewTitle={previewTitle || title}
                        mediaClass={`text-primary bg-primary-subtle fs-9`}
                    />
                </Flex>
            );
        },
    };
};
