import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import FalconDropzone from '@/components/common/FalconDropzone';
import avatarImg from '@/assets/images/team/avatar.png';
import { isIterableArray } from '@/helpers/utils';
import Avatar from '@/components/common/Avatar';
import cloudUpload from '@/assets/images/icons/cloud-upload.svg';
import { AuthWizardContext } from '@/context/Context';
import Flex from '@/components/common/Flex';
import { Col, Row } from 'react-bootstrap';

const AccountType = ({
                          control,
                          register,
                          errors,
                          setError,
                          clearErrors,
                          setValue,
                          watch,
                          invitation
                      }) => {
  const { user } = useContext(AuthWizardContext);

  return (
    <>
      <WizardInput
        type="select"
        label="Account Type"
        name="companyType"
        placeholder="Select your Account Type..."
        control={control}
        register={register}
        errors={errors}
        setError={setError}
        clearErrors={clearErrors}
        setValue={setValue}
        value={invitation?.email ? 1 : undefined}
        options={[{key: 1, value: 'Issuing Bank'}, {key: 2, value: 'Perso. Bureau'}, {key: 3, value: 'Laboratory'}]}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('companyType', {
              required: 'Account Type field is required.'
          })
        }}
      />
    </>
  );
};

AccountType.propTypes = {
    control: PropTypes.any,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object,
    setError: PropTypes.func,
    clearErrors: PropTypes.func,
    setValue: PropTypes.func,
    watch: PropTypes.func,
    invitation: PropTypes.object
};

export default AccountType;
