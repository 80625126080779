import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
} from "react";
import { Col, Row } from "react-bootstrap";
import useQuiz from "@/hooks/biz/useQuiz";
import FormErrorMessage from "@/components/shared/FormErrorMessage";

const BulkDeleteQuizForm = forwardRef((props, ref) => {
    const selectedData = useMemo(() => {
        return props?.selectedData || [];
    }, [props?.selectedData]);

    const { deleteQuizProcessing, deleteQuizErrorMessage, deleteQuiz } =
        useQuiz();

    const errorMessage = useMemo(() => {
        return deleteQuizErrorMessage;
    }, [deleteQuizErrorMessage]);

    const onSubmit = useCallback(() => {
        if (selectedData.length > 0) {
            return deleteQuiz(selectedData?.map(item => item.id) || []);
        }
    }, [selectedData, deleteQuiz]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
        }),
        [onSubmit],
    );

    return (
        <Row className="g-3">
            <Col lg={12}>
                <FormErrorMessage errorMessage={errorMessage} />
                <span>{`Bạn có chắc chắn muốn xoá ${selectedData?.length || 0} Bài kiểm tra đã chọn không?`}</span>
            </Col>
        </Row>
    );
});

export default BulkDeleteQuizForm;
