export const version = "1.0.0";
export const navbarBreakPoint = "xl"; // Vertical navbar breakpoint
export const topNavbarBreakpoint = "lg";
export const themeVariants = ["light", "dark", "auto"];
export const settings = {
    isFluid: false,
    isRTL: false,
    isDark: false,
    theme: "light",
    navbarPosition: "top",
    showBurgerMenu: false, // controls showing vertical nav on mobile
    currency: "$",
    isNavbarVerticalCollapsed: false, // toggle vertical navbar collapse
    navbarStyle: "transparent",
};
