import React from 'react';
import { Link } from 'react-router-dom';
import LoginForm from '@/components/authentication/LoginForm';

import AuthDefaultLayout from '@/layouts/AuthDefaultLayout';

const Login = () => {
  return (
    <AuthDefaultLayout
      leftSideContent={
        <p className="text-white">
          Bạn chưa có tài khoản?
          <br />
          <Link
            className="text-white text-decoration-underline fw-bold"
            to="/auth/register"
          >
              Đăng ký ở đây!
          </Link>
        </p>
      }
    >
      <h3>Đăng nhập</h3>
        <LoginForm hasLabel />
    </AuthDefaultLayout>
  );
};

export default Login;
