import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import logoutImg from '@/assets/images/icons/spot-illustrations/45.png';
import useAuth from "@/hooks/useAuth";

const LogoutForm = ({titleTag: TitleTag}) => {
    const {
        logout
    } = useAuth();

    useEffect( () => {
        (async () => logout())();
    }, [logout]);

    return (
    <>
      <img
        className="d-block mx-auto mb-4"
        src={logoutImg}
        alt="shield"
        width={100}
      />
      <TitleTag>See you again!</TitleTag>
      <p>
        Thanks for using WebCMS. You are <br className="d-none d-sm-block" />
        now successfully signed out.
      </p>
      <Button
        as={Link}
        color="primary"
        size="sm"
        className="mt-3"
        to={`/auth/login`}
      >
        <FontAwesomeIcon
          icon="chevron-left"
          transform="shrink-4 down-1"
          className="me-1"
        />
        Return to Login
      </Button>
    </>
  );
};

LogoutForm.propTypes = {
  titleTag: PropTypes.string
};

LogoutForm.defaultProps = {
  titleTag: 'h4'
};

export default LogoutForm;
