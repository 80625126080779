import { useCallback, useMemo, useState } from "react";

import useProtectedHttp from "@/hooks/useProtectedHttp";
import useProcessing from "@/hooks/useProcessing";
import PropTypes from "prop-types";
import { isNotEmptyArray } from "@/utils/common";

const useQuestion = () => {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post } = useProtectedHttp();

    const [question, setQuestion] = useState(undefined);
    const [questionsPagination, setQuestionsPagination] = useState({});

    //create
    const createQuestionProcessing = useMemo(() => {
        return !!processing?.createQuestion;
    }, [processing?.createQuestion]);

    const createQuestionErrorMessage = useMemo(() => {
        return errorMessage?.createQuestion;
    }, [errorMessage?.createQuestion]);

    const createQuestion = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "createQuestion",
                async () => {
                    try {
                        if (!inSilentMode) setQuestion(undefined);
                        const result = await post(`/v1/questions/create`, data);
                        setQuestion(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    //search
    const searchQuestionProcessing = useMemo(() => {
        return !!processing?.searchQuestion;
    }, [processing?.searchQuestion]);

    const searchQuestionErrorMessage = useMemo(() => {
        return errorMessage?.searchQuestion;
    }, [errorMessage?.searchQuestion]);

    const searchQuestion = useCallback(
        (
            data = {},
            options = { page: 0, size: 10, sort: "" },
            inSilentMode = false,
        ) => {
            return process(
                "searchQuestion",
                async () => {
                    try {
                        const { page, size, sort } = options || {};
                        if (!inSilentMode) setQuestionsPagination({});
                        const result =
                            size > 0
                                ? await post(
                                      `/v1/questions/search?page=${page}&size=${size}&sort=${sort}`,
                                      data,
                                  )
                                : await get(`/v1/questions`);
                        setQuestionsPagination(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post, get],
    );

    //get
    const getQuestionProcessing = useMemo(() => {
        return !!processing?.getQuestion;
    }, [processing?.getQuestion]);

    const getQuestionErrorMessage = useMemo(() => {
        return errorMessage?.getQuestion;
    }, [errorMessage?.getQuestion]);

    const getQuestion = useCallback(
        (id, options = { includes: ["answer"] }, inSilentMode = false) => {
            return process(
                "getQuestion",
                async () => {
                    try {
                        const { includes } = options || {};
                        if (!inSilentMode) setQuestion(undefined);
                        const result = await get(
                            `/v1/questions/${id}?includes=${(includes || []).join(",")}`,
                        );
                        setQuestion(result);

                        return Promise.resolve(result?.question);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, get],
    );

    //delete
    const deleteQuestionProcessing = useMemo(() => {
        return !!processing?.deleteQuestion;
    }, [processing?.deleteQuestion]);

    const deleteQuestionErrorMessage = useMemo(() => {
        return errorMessage?.deleteQuestion;
    }, [errorMessage?.deleteQuestion]);

    const deleteQuestion = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "deleteQuestion",
                async () => {
                    try {
                        if (!inSilentMode) setQuestion(undefined);
                        const result = await post(`/v1/questions/delete`, data);
                        setQuestion(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    //update
    const updateQuestionProcessing = useMemo(() => {
        return !!processing?.updateQuestion;
    }, [processing?.updateQuestion]);

    const updateQuestionErrorMessage = useMemo(() => {
        return errorMessage?.updateQuestion;
    }, [errorMessage?.updateQuestion]);

    const updateQuestion = useCallback(
        (data, options = { updateFields: [] }, inSilentMode = false) => {
            return process(
                "updateQuestion",
                async () => {
                    try {
                        const { id } = data || {};
                        const { updateFields } = options || {};
                        const result = isNotEmptyArray(updateFields)
                            ? await post(`/v1/questions/${id}/patch`, {
                                  data,
                                  updateFields,
                              })
                            : await post(`/v1/questions/${id}/update`, data);
                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    return {
        createQuestionProcessing,
        createQuestionErrorMessage,
        createQuestion,
        searchQuestionProcessing,
        searchQuestionErrorMessage,
        searchQuestion,
        questionsPagination,
        getQuestionProcessing,
        getQuestionErrorMessage,
        getQuestion,
        question,
        deleteQuestionProcessing,
        deleteQuestionErrorMessage,
        deleteQuestion,
        updateQuestionProcessing,
        updateQuestionErrorMessage,
        updateQuestion,
    };
};

useQuestion.propTypes = {
    processing: PropTypes.shape({
        createQuestion: PropTypes.bool,
        searchQuestion: PropTypes.bool,
        getQuestion: PropTypes.bool,
        deleteQuestion: PropTypes.bool,
        updateQuestion: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        createQuestion: PropTypes.string,
        searchQuestion: PropTypes.string,
        getQuestion: PropTypes.string,
        deleteQuestion: PropTypes.string,
        updateQuestion: PropTypes.bool,
    }),
};

export default useQuestion;
