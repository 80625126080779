import {useCallback, useMemo, useState} from 'react';

import useProtectedHttp from "@/hooks/useProtectedHttp";
import useProcessing from "@/hooks/useProcessing";
import PropTypes from "prop-types";
import {isNotEmptyArray} from "@/utils/common";

const useProject = () => {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post } = useProtectedHttp();

    const [project, setProject] = useState(undefined);
    const [projectsPagination, setProjectsPagination] = useState({});


    //create
    const createProjectProcessing = useMemo(() => {
        return !!processing?.createProject;
    }, [processing?.createProject]);

    const createProjectErrorMessage = useMemo(() => {
        return errorMessage?.createProject;
    }, [errorMessage?.createProject]);

    const createProject = useCallback((data, inSilentMode = false) => {
        return process('createProject', async () => {
            try {
                if(!inSilentMode) setProject(undefined);
                const result = await post(`/v1/projects/create`, data);
                setProject(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);


    //search
    const searchProjectProcessing = useMemo(() => {
        return !!processing?.searchProject;
    }, [processing?.searchProject]);

    const searchProjectErrorMessage = useMemo(() => {
        return errorMessage?.searchProject;
    }, [errorMessage?.searchProject]);

    const searchProject = useCallback( (data = {}, options = {page: 0, size: 10, sort: ''}, inSilentMode = false) => {
        return process('searchProject', async () => {
            try {
                const {page, size, sort} = options || {};
                if(!inSilentMode) setProjectsPagination({});
                const result = size > 0 ? await post(`/v1/projects/search?page=${page}&size=${size}&sort=${sort}`, data)
                    : await get(`/v1/projects`);
                setProjectsPagination(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post, get]);


    //get
    const getProjectProcessing = useMemo(() => {
        return !!processing?.getProject;
    }, [processing?.getProject]);

    const getProjectErrorMessage = useMemo(() => {
        return errorMessage?.getProject;
    }, [errorMessage?.getProject]);

    const getProject = useCallback((id, options = {includes: []}, inSilentMode = false) => {
        return process('getProject', async () => {
            try {
                const {includes} = options || {};
                if(!inSilentMode) setProject(undefined);
                const result = await get(`/v1/projects/${id}?includes=${(includes || []).join(',')}`);
                console.log('getProject', result);
                setProject(result);

                return Promise.resolve(result?.project);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, get]);

    //delete
    const deleteProjectProcessing = useMemo(() => {
        return !!processing?.deleteProject;
    }, [processing?.deleteProject]);

    const deleteProjectErrorMessage = useMemo(() => {
        return errorMessage?.deleteProject;
    }, [errorMessage?.deleteProject]);

    const deleteProject = useCallback((data, inSilentMode = false) => {
        return process('deleteProject', async () => {
            try {
                if(!inSilentMode) setProject(undefined);
                const result = await post(`/v1/projects/delete`, data);
                setProject(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);


    //update
    const updateProjectProcessing = useMemo(() => {
        return !!processing?.updateProject;
    }, [processing?.updateProject]);

    const updateProjectErrorMessage = useMemo(() => {
        return errorMessage?.updateProject;
    }, [errorMessage?.updateProject]);

    const updateProject = useCallback((data, options = {updateFields: []}, inSilentMode = false) => {
        return process('updateProject', async () => {
            try {
                const {id} = data || {};
                const {updateFields} = options || {};
                const result = isNotEmptyArray(updateFields) ? await post(`/v1/projects/${id}/patch`, {data, updateFields}) : await post(`/v1/projects/${id}/update`, data);
                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);

    return {
        createProjectProcessing, createProjectErrorMessage, createProject,
        searchProjectProcessing, searchProjectErrorMessage, searchProject, projectsPagination,
        getProjectProcessing, getProjectErrorMessage, getProject, project,
        deleteProjectProcessing, deleteProjectErrorMessage, deleteProject,
        updateProjectProcessing, updateProjectErrorMessage, updateProject,
    };
};

useProject.propTypes = {
    processing: PropTypes.shape({
        createProject: PropTypes.bool,
        searchProject: PropTypes.bool,
        getProject: PropTypes.bool,
        deleteProject: PropTypes.bool,
        updateProject: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        createProject: PropTypes.string,
        searchProject: PropTypes.string,
        getProject: PropTypes.string,
        deleteProject: PropTypes.string,
        updateProject: PropTypes.bool,
    }),
};

export default useProject;
