import React from 'react';
import PropTypes from 'prop-types';
import {Button, Nav} from "react-bootstrap";

export const ViewMode = {
    TREE: "Tree",
    TABLE: "Table"
};

export const ViewModeNavBar = ({
  viewMode, onViewModeChange
}) => (
    <Nav variant="pills" className="nav-pills-falcon m-0" activeKey={viewMode} onSelect={(eventKey) => {
        if(onViewModeChange) onViewModeChange(eventKey);
    }}>
      <Nav.Item>
        <Nav.Link as={Button} size="sm" eventKey={ViewMode.TREE}>
          {ViewMode.TREE}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Button} size="sm" eventKey={ViewMode.TABLE}>
          {ViewMode.TABLE}
        </Nav.Link>
      </Nav.Item>
    </Nav>
);

ViewModeNavBar.propTypes = {
    viewMode: PropTypes.oneOf([ViewMode.TREE, ViewMode.TABLE]),
    onViewModeChange: PropTypes.func,
};

ViewModeNavBar.defaultProps = { viewMode: ViewMode.TREE };

export default ViewModeNavBar;
