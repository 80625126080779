import {useCallback, useMemo} from 'react';

import {execute} from "@/utils/common";
import useAppState from "@/hooks/useAppState";

const useProcessing = () => {
    const { state, dispatch } = useAppState();
    const processing = useMemo(() => {
        return state?.processing;
    }, [state?.processing]);

    const errorMessage = useMemo(() => {
        return state?.errorMessage;
    }, [state?.errorMessage]);

    const setProcessingState = useCallback((action, state) => {
        if (action) dispatch({type: action, payload: {processing: {[action]: state}}});
    }, [dispatch]);

    const setErrorMessageState = useCallback((action, state) => {
        if (action) dispatch({type: action, payload: {errorMessage: {[action]: state}}});
    }, [dispatch]);

    const process = useCallback(async (action, callback, inSilentMode = false) => {
        try {
            setErrorMessageState(action, null);
            if(!inSilentMode) setProcessingState(action, true);
            return {result: await execute(callback)};
        } catch (error) {
            setErrorMessageState(action, error?.message);
            return {error: error}
        } finally {
            if(!inSilentMode) setProcessingState(action, false);
        }
    }, [setErrorMessageState, setProcessingState])

    return { processing,  errorMessage,  process };
};

export default useProcessing;
