import React from "react";
import PropTypes from "prop-types";
import Flex from "@/components/common/Flex";
import classNames from "classnames";
import { Dropdown, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { execute } from "@/utils/common";

import { FaCheck, FaListCheck } from "react-icons/fa6";

const SidebarDropdownAction = ({ onAction }) => (
    <Dropdown className="dropdown-active-trigger dropdown-chat">
        <Dropdown.Toggle
            id="dropdown-button"
            className="hover-actions text-400 dropdown-caret-none fs-9 z-1 "
            variant="link"
            size="sm"
        >
            <FontAwesomeIcon icon="cog" transform="shrink-3 down-4" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="py-2">
            <Dropdown.Item
                onClick={() => execute(onAction, "edit")}
                className="cursor-pointer"
            >
                Sửa
            </Dropdown.Item>
            {/*<Dropdown.Divider />*/}
            <Dropdown.Item
                onClick={() => execute(onAction, "delete")}
                className="cursor-pointer text-danger"
            >
                Xoá
            </Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
);

SidebarDropdownAction.propTypes = {
    onAction: PropTypes.func,
};

const QuestionNumber = ({ number }) => {
    const circleStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        backgroundColor: "rgba(128, 128, 128, 0.8)",
        color: "white",
        fontSize: "12px",
        fontWeight: "bold",
    };

    return <div style={circleStyle}>{`Q${number}`}</div>;
};

QuestionNumber.propTypes = {
    number: PropTypes.number,
};

const QuestionItem = ({ data, index, active = false, onAction }) => {
    return (
        <Nav.Link
            eventKey={index}
            className={classNames(`chat-contact hover-actions-trigger p-3`, {
                "unread-message": active,
                "read-message": !active,
            })}
        >
            <div className="d-md-none d-lg-block">
                <SidebarDropdownAction
                    onAction={action => execute(onAction, action, data)}
                />
            </div>
            <Flex>
                <QuestionNumber number={index + 1} />
                <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                    <Flex justifyContent="between">
                        <h6 className="mb-0 chat-contact-title">
                            {data?.title || `Câu hỏi ${index + 1}`}
                        </h6>
                        <span className="message-time fs-11">
                            {data?.questionType === 1 && <FaCheck />}
                            {data?.questionType !== 1 && <FaListCheck />}
                        </span>
                    </Flex>
                    <div className="min-w-0">
                        <div className="chat-contact-content pe-3">
                            {`Điểm: ${data?.point || 0}`}
                        </div>
                    </div>
                </div>
            </Flex>
        </Nav.Link>
    );
};

QuestionItem.propTypes = {
    data: PropTypes.object,
    index: PropTypes.number,
    active: PropTypes.bool,
    onAction: PropTypes.func,
};

export default QuestionItem;
