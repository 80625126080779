import React, {forwardRef, useCallback, useImperativeHandle, useMemo} from 'react';
import {Col, Row} from 'react-bootstrap';
import useAds from "@/hooks/biz/useAds";
import FormErrorMessage from "@/components/shared/FormErrorMessage";

const BulkDeleteAdsForm = forwardRef((props, ref) => {
    const selectedData = useMemo(() => {
        return props?.selectedData || [];
    }, [props?.selectedData])

    const {
        deleteAdsProcessing, deleteAdsErrorMessage, deleteAds,
    } = useAds();

    const errorMessage = useMemo(() => {
        return deleteAdsErrorMessage;
    }, [deleteAdsErrorMessage])


    const onSubmit = useCallback(() => {
        if (selectedData.length > 0) {
            return deleteAds(selectedData?.map(item => item.id) || []);
        }
    }, [selectedData, deleteAds]);

    useImperativeHandle(ref, () => ({
        submit: onSubmit,
    }), [onSubmit]);


    return (
        <Row className="g-3">
            <Col lg={12}>
                <FormErrorMessage errorMessage={errorMessage}/>
                <span>{`Bạn có chắc chắn muốn xoá ${selectedData?.length || 0} Quảng cáo đã chọn không?`}</span>
            </Col>
        </Row>
    );
});

export default BulkDeleteAdsForm;
