import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Col, ProgressBar} from 'react-bootstrap';
import Flex from '@/components/common/Flex';
import Avatar, { AvatarGroup } from '@/components/common/Avatar';
import { Link } from 'react-router-dom';
import FalconCardFooterLink from '@/components/common/FalconCardFooterLink';
import FalconCardHeader from '@/components/common/FalconCardHeader';
import AdvanceTableWrapper from '@/components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from '@/components/common/advance-table/AdvanceTable';
import useProject from "@/hooks/biz/useProject";
import Loading from "@/components/extra/Loading";
import mastercard from '@/assets/images/cards/mastercard.png';
import SubtleBadge from "@/components/common/SubtleBadge";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AdvanceTablePagination from "@/components/common/advance-table/AdvanceTablePagination";
import { GrValidate } from "react-icons/gr";
import { FaCheckToSlot } from "react-icons/fa6";
import usePostCallback from "@/hooks/usePostCallback";

const getCardBrandLogo = (cardBrandCode) => {
  return mastercard;
}

const columns = [
  {
    accessor: 'title',
    Header: 'ID',
    headerProps: { className: 'text-center text-800' },
    Cell: rowData => {
      const { projectUid, cardBrandCode, issuingCountry, issuerCompanyName } = rowData.row.original;
      return (
        <Flex alignItems="center" className="position-relative">
          <Avatar
            name={cardBrandCode}
            width="60"
            rounded={'3'}
            alt={cardBrandCode}
            src={getCardBrandLogo(cardBrandCode)}
            mediaClass={`text-primary bg-primary-subtle fs-9`}
          />
          <div className="flex-1 ms-3">
            <h6 className="mb-0 fw-semibold">
              <Link className="text-1100 stretched-link" to={`/projects/${projectUid}`}>
                {projectUid}
              </Link>
            </h6>
            <p className="fs-11 mb-0 text-500">{issuerCompanyName} ({issuingCountry})</p>
          </div>
        </Flex>
      );
    }
  },
  // {
  //   accessor: 'cardBrandCode',
  //   Header: 'Card Brand Code',
  //   cellProps: {
  //     className: 'text-center fw-semibold fs-10'
  //   },
  //   headerProps: {
  //     className: 'text-center text-800'
  //   }
  // },
  // {
  //   accessor: 'version',
  //   Header: 'Version',
  //   cellProps: {
  //     className: 'text-center fw-semibold fs-10'
  //   },
  //   headerProps: {
  //     className: 'text-center text-800'
  //   }
  // },
    {
        accessor: 'profileType',
        Header: 'Profile Type',
        cellProps: {
            className: 'text-center fw-semibold fs-10'
        },
        headerProps: {
            className: 'text-center text-800'
        }
    },
    {
        accessor: 'validationType',
        Header: 'Validation Type',
        cellProps: {
            className: 'text-center fw-semibold fs-10'
        },
        headerProps: {
            className: 'text-center text-800'
        }
    },
    {
        accessor: 'applications',
        Header: 'Applications',
        cellProps: {
            className: 'text-center fw-semibold fs-10'
        },
        headerProps: {
            className: 'text-center text-800'
        }
    },
    {
        accessor: 'owner',
        Header: 'Owner',
        headerProps: { className: 'text-center text-800' },
        Cell: rowData => {
            const { companyName, createDate } = rowData.row.original;
            return (
                <Flex alignItems="center" className="position-relative">

                    <div className="flex-1 ms-3">
                        <h6 className="mb-0 fw-semibold fs-10">
                            {companyName}
                        </h6>
                        <p className="fs-11 mb-0 text-500">{`Created: ${createDate}`}</p>
                    </div>
                </Flex>
            );
        }
    },
    // {
    //     accessor: 'companyName',
    //     Header: 'Owner',
    //     cellProps: {
    //         className: 'text-center fw-semibold fs-10'
    //     },
    //     headerProps: {
    //         className: 'text-center text-800'
    //     }
    // },
    // {
    //     accessor: 'createDate',
    //     Header: 'Created Time',
    //     cellProps: {
    //         className: 'text-center fw-semibold fs-10'
    //     },
    //     headerProps: {
    //         className: 'text-center text-800'
    //     }
    // },
  {
    accessor: 'status',
    Header: 'Product Validation',
    headerProps: {
      className: 'text-center text-900'
    },
    cellProps: {
      className: 'fs-9'
    },
    Cell: rowData => {
        const { projectUid, status } = rowData.row.original;
        return (
            <Link to={`/projects/${projectUid}/validate-product`}>
                <SubtleBadge
                    pill
                    bg={classNames({
                        primary: status === 1
                        // success: status === 4,
                        // primary: status === 3,
                        // warning: status === 2,
                        // secondary: status === 1
                    })}
                    className="d-flex flex-center"
                >
                    {status === 1 && <FaCheckToSlot className={'text-primary mx-1'} />}
                    <p className="mb-0">
                        {/*{status === 4 && 'Completed'}*/}
                        {/*{status === 3 && 'Processing'}*/}
                        {/*{status === 3 && 'Pending'}*/}
                        {/*{status === 1 && 'New'}*/}
                        {status === 1 && 'Validate'}
                    </p>


                    {/*<FontAwesomeIcon*/}
                    {/*    icon={classNames({*/}
                    {/*        'check': status === 4,*/}
                    {/*        'redo': status === 3,*/}
                    {/*        'stream': status === 2,*/}
                    {/*        'check-to-slot' : status === 1,*/}
                    {/*    })}*/}
                    {/*    transform="shrink-2"*/}
                    {/*    className="ms-1"*/}
                    {/*/>*/}
                </SubtleBadge>
            </Link>
        );
    }
  },
  {
    accessor: 'progress',
    Header: 'Progress',
    headerProps: {
      className: 'text-center text-800'
    },
    cellProps: {
      className: 'text-center'
    },
    Cell: rowData => {
      const {status } = rowData.row.original;
      return (
          <ProgressBar
              now={status === 1 ? 0 : (status === 1 ? 100 : 0)}
              style={{ height: 5 }}
              className="rounded-pill align-middle"
              variant="progress-gradient"
          />
      );
    }
  },
  {
    accessor: 'dueDate',
    Header: 'Due Date',
    cellProps: {
      className: 'text-center fw-semibold fs-10'
    },
    headerProps: {
      className: 'text-center text-800'
    }
  },
    {
        accessor: 'labCompanyName',
        Header: 'Laboratory',
        cellProps: {
            className: 'text-center fw-semibold fs-10'
        },
        headerProps: {
            className: 'text-center text-800'
        }
    },
    {
        accessor: 'persoBureauCompanyName',
        Header: 'Perso Bureau',
        cellProps: {
            className: 'text-center fw-semibold fs-10'
        },
        headerProps: {
            className: 'text-center text-800'
        }
    },
  // {
  //   accessor: 'members',
  //   Header: 'Members',
  //   disableSortBy: true,
  //   headerProps: {
  //     className: 'text-end text-800'
  //   },
  //   Cell: rowData => {
  //     const { members } = rowData.row.original;
  //     return members ? (
  //       <AvatarGroup className="justify-content-end">
  //         {members?.map(({ img, name, id }) => {
  //           return (
  //             <Avatar
  //               src={img && img}
  //               key={id}
  //               name={name && name}
  //               isExact
  //               className="border border-3 rounded-circle border-200"
  //             />
  //           );
  //         })}
  //       </AvatarGroup>
  //     ) : (<></>);
  //   }
  // }
];

const ListProject = ({ limit = 0 }) => {

    const {searchProjectProcessing, searchProjectErrorMessage, searchProject, projectsPagination} = useProject();

    const [pageSize, setPageSize] = useState(50);


    const goToPage = useCallback((page) => {
        searchProject({})(page, limit > 0 ? limit : pageSize).then();
    }, [searchProject, pageSize, limit])

    // const called = useRef(false);
    // useEffect(() => {
    //     if (!called.current) {
    //         called.current = true;
    //         goToPage(0);
    //     }
    // }, [goToPage])

    usePostCallback([() => goToPage(0)], [goToPage])

    const {content:data, totalPages, totalElements, last, first, size, number:pageIndex} = projectsPagination;

  return (
      <AdvanceTableWrapper
          columns={columns}
          data={data || []}
          sortable={false}
          pagination={true}
          perPage={pageSize}
      >
          <Card className="h-100">
              <FalconCardHeader title="Projects" titleTag="h5" />
              <Card.Body className="p-0">
                  <AdvanceTable
                      table
                      headerClassName="bg-body-tertiary text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                          borderless: true,
                          className: 'fs-11 mb-0 overflow-hidden'
                      }}
                  />
              </Card.Body>
              {limit > 0 ? (
                  <FalconCardFooterLink title="Show all projects" size="sm" to={'/projects'} />
              ) : (
                  <Card.Footer>
                      {searchProjectProcessing ? (
                          <Col className="d-flex justify-content-center">
                              <Loading size={48}/>
                          </Col>
                      ) : (
                          <AdvanceTablePagination
                              canPreviousPage={!first}
                              canNextPage={!last}
                              previousPage={() => goToPage((pageIndex || 0) > 1 ? (pageIndex || 0) - 1 : 0)}
                              nextPage={() => goToPage((pageIndex || 0) < (totalPages || 0) - 1 ? (pageIndex || 0) + 1 : (totalPages || 0) - 1) }
                              pageCount={(totalPages || 0)}
                              pageIndex={pageIndex || 0}
                              gotoPage={goToPage}
                          />
                      )}
                  </Card.Footer>
              )}
          </Card>
      </AdvanceTableWrapper>
  );
};

ListProject.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
      duration: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      avatar: PropTypes.shape({
        name: PropTypes.string.isRequired,
        size: PropTypes.string
      }),
      projectName: PropTypes.string.isRequired,
      members: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          img: PropTypes.string,
          size: PropTypes.string
        })
      ),
      date: PropTypes.string.isRequired,
      isLast: PropTypes.bool
    })
  )
};

export default ListProject;
