import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Card, Dropdown} from 'react-bootstrap';
import {breakpoints, capitalize} from '@/helpers/utils';
import {topNavbarBreakpoint} from '@/config';

const TopNavbarDropdown = ({routes:{title, children}}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    return (
        <Dropdown
            show={dropdownOpen}
            onToggle={() => setDropdownOpen(!dropdownOpen)}
            onMouseOver={() => {
                let windowWidth = window.innerWidth;
                if (windowWidth >= breakpoints[topNavbarBreakpoint]) {
                    setDropdownOpen(true);
                }
            }}
            onMouseLeave={() => {
                let windowWidth = window.innerWidth;
                if (windowWidth >= breakpoints[topNavbarBreakpoint]) {
                    setDropdownOpen(false);
                }
            }}
        >
            <Dropdown.Toggle as={Link} to="#!" className="nav-link fw-semibold">
                {capitalize(title || '')}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-card mt-0 dropdown-caret">
                {/* {children} */}
                <Card className={'shadow-none dark__bg-1000'}>
                    <Card.Body className={'scrollbar max-h-dropdown p-0 py-2'}>
                        {children?.map(route => (
                            <Dropdown.Item
                                key={route.name}
                                as={Link}
                                className={route.active ? 'link-600' : 'text-500'}
                                to={route.to}
                            >
                                {route.name}
                            </Dropdown.Item>
                        ))}
                    </Card.Body>
                </Card>
            </Dropdown.Menu>
        </Dropdown>
    );
};

TopNavbarDropdown.propTypes = {
    routes: PropTypes.shape({
        title: PropTypes.string.isRequired,
        children: PropTypes.node
    }).isRequired,
};

export default TopNavbarDropdown;
