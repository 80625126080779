import React, {useState, forwardRef, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import IntlTelInput from "intl-tel-input/reactWithUtils";
import "intl-tel-input/styles";
import {
    Control,
    Controller,
    FieldError,
    FieldErrorsImpl,
    FieldValues,
    Merge,
} from "react-hook-form";

const CustomDateInput = forwardRef(
  (
    { value, onClick, isInvalid, isValid, formControlProps, errorMessage },
    ref
  ) => {
    return (
      <>
        <Form.Control
          ref={ref}
          isInvalid={isInvalid}
          isValid={isValid}
          value={value}
          onClick={onClick}
          {...formControlProps}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </>
    );
  }
);
/*
initialValue?: string;
        onChangeNumber?: (number: string) => void;
        onChangeCountry?: (country: string) => void;
        onChangeValidity?: (valid: boolean) => void;
        onChangeErrorCode?: (errorCode: number | null) => void;
        usePreciseValidation?: boolean;
        initOptions?: SomeOptions;
        inputProps?: object;
 */
const PhoneNumberInput = (
    {control, name, isRequired, register, initOptions, onChangePhoneNumber, errors, setError, clearErrors, setValue, formControlProps },
) => {

    const [isValid, setIsValid] = useState(true);
    const [errorCode, setErrorCode] = useState(undefined);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [number, setNumber] = useState('');

    const errorMessages = [
        "Invalid Phone Number",
        "Invalid Country Code",
        "Value Too short",
        "Value Too long",
        "Invalid Phone Number",
    ];

    useEffect(() => {
        if(isRequired){
            if(number?.length === 0){
                setErrorMessage('Phone Number is required');
                return;
            }
            const errorMessage = errorCode ? errorMessages[errorCode] : null;
            setErrorMessage(errorMessage);
            // if(errorMessage){
            //     setError(name, { type: 'custom', message: errorMessage }, { shouldFocus: true });
            // } else {
            //     setError(name, null);
            // }
        } else {
            if(number?.length > 0) {
                const errorMessage = errorCode ? errorMessages[errorCode] : null;
                setErrorMessage(errorMessage);
                // if(errorMessage){
                //     setError(name, { type: 'custom', message: errorMessage }, { shouldFocus: true });
                // } else {
                //     setError(name, null);
                // }
            }
        }
    }, [name, isRequired, number, errorCode]);

    return (
        <>
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, value } }) => (
                   <>
                       <IntlTelInput
                           onChangeNumber={(number) => {
                               setNumber(number || '');
                               onChange(number);
                           }}
                           onChangeErrorCode={(errorCode) => setErrorCode(errorCode)}
                           initOptions={{
                               initialCountry: "us",
                           }}
                           initialValue={value}
                           inputProps={{
                               className: `form-control`
                           }}
                       />
                       <Form.Control.Feedback type="invalid" className={ errorMessage ? 'block' : 'hidden' }>
                           {errorMessage}
                       </Form.Control.Feedback>
                   </>
                )}
            />

        </>
    );
};

const WizardInput = ({
  label,
  name,
  control,
  register,
  errors,
  setError,
  clearErrors,
  type = 'text',
  options = [],
  placeholder,
  formControlProps,
  formGroupProps,
  setValue,
  datepickerProps,
  value,
}) => {
  const [date, setDate] = useState(null);

  useEffect(() => {
      if(value) setValue(name, value, {shouldValidate: true});
  }, [])

  if (type === 'date') {
    return (
      <Form.Group {...formGroupProps}>
        {!!label && <Form.Label>{label}</Form.Label>}

        <DatePicker
          selected={date}
          onChange={date => {
            setDate(date);
            setValue(name, date);
          }}
          customInput={
            <CustomDateInput
              formControlProps={formControlProps}
              errorMessage={errors[name]?.message}
              isInvalid={errors[name]}
              isValid={Object.keys(errors).length > 0 && !errors[name]}
            />
          }
          {...datepickerProps}
        />
      </Form.Group>
    );
  }

  if (type === 'checkbox' || type === 'switch' || type === 'radio') {
    return (
      <Form.Check type={type} id={name + Math.floor(Math.random() * 100)}>
        <Form.Check.Input
          type={type}
          {...formControlProps}
          isInvalid={errors[name]}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
        />
        <Form.Check.Label className="ms-2">{label}</Form.Check.Label>
        <Form.Control.Feedback type="invalid" className="mt-0">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Check>
    );
  }
  if (type === 'select') {
    return (
      <Form.Group {...formGroupProps}>
        <Form.Label>{label}</Form.Label>
          {value && (
              <Form.Select
                  type={type}
                  {...formControlProps}
                  value={value}
                  defaultValue={value}
                  disabled={true}
                  isInvalid={errors[name]}
                  isValid={Object.keys(errors).length > 0 && !errors[name]}
              >
                  <option value="">{placeholder}</option>
                  {options.map(option => {
                      if (option && typeof option === 'object' && !Array.isArray(option)) {
                          const {key, value} = option;
                          return (
                              <option value={key} key={key} selected={key === value}>
                                  {value}
                              </option>
                          );
                      }
                      else {
                          return (
                              <option value={option} key={option} selected={option === value}>
                                  {option}
                              </option>
                          );
                      }
                  })}
              </Form.Select>
          )}
          {!value && (
              <Form.Select
                  type={type}
                  {...formControlProps}
                  isInvalid={errors[name]}
                  isValid={Object.keys(errors).length > 0 && !errors[name]}
              >
                  <option value="">{placeholder}</option>
                  {options.map(option => {
                      if (option && typeof option === 'object' && !Array.isArray(option)) {
                          const {key, value} = option;
                          return (
                              <option value={key} key={key}>
                                  {value}
                              </option>
                          );
                      }
                      else {
                          return (
                              <option value={option} key={option}>
                                  {option}
                              </option>
                          );
                      }
                  })}
              </Form.Select>
          )}
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
  if (type === 'textarea') {
    return (
      <Form.Group {...formGroupProps}>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          as="textarea"
          placeholder={placeholder}
          {...formControlProps}
          isValid={Object.keys(errors).length > 0 && !errors[name]}
          isInvalid={errors[name]}
          rows={4}
        />
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

    if (type === 'phone') {
        return (
            <Form.Group {...formGroupProps}>
                {!!label && <Form.Label>{label}</Form.Label>}
                <PhoneNumberInput
                    name={name}
                    control={control}
                    register={register}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    initOptions={{
                        initialCountry: "us",
                    }}
                    onChangePhoneNumber={(value) => {
                    }}

                    {...formGroupProps}
                />
                <Form.Control.Feedback type="invalid">
                    {errors[name]?.message}
                </Form.Control.Feedback>

                {errors[name]?.message}
            </Form.Group>
        );
    }
  return (
    <Form.Group {...formGroupProps}>
      <Form.Label>{label}</Form.Label>
        {value && (
            <Form.Control
                type={type}
                placeholder={placeholder}
                {...formControlProps}
                value={value}
                disabled={true}
                isInvalid={errors[name]}
                isValid={Object.keys(errors).length > 0 && !errors[name]}
            />
        )}
        {!value && (
            <Form.Control
                type={type}
                placeholder={placeholder}
                {...formControlProps}
                isInvalid={errors[name]}
                isValid={Object.keys(errors).length > 0 && !errors[name]}
            />
        )}

      <Form.Control.Feedback type="invalid">
        {errors[name]?.message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

CustomDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  formControlProps: PropTypes.object,
  errorMessage: PropTypes.string
};

PhoneNumberInput.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    initOptions: PropTypes.object,
    onChangePhoneNumber: PropTypes.func,
    errors: PropTypes.object,
    setError: PropTypes.func,
    clearErrors: PropTypes.func,
    setValue: PropTypes.func
};

WizardInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  control: PropTypes.any,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
  setValue: PropTypes.func,
  type: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  formControlProps: PropTypes.object,
  formGroupProps: PropTypes.object,
  datepickerProps: PropTypes.object
};

WizardInput.defaultProps = { required: false };

export default WizardInput;
