import { AuthWizardContext } from '@/context/Context';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import celebration from './lottie/celebration.json';
import WizardInput from "@/components/authentication/default/wizard/WizardInput";
import {Link, useNavigate} from "react-router-dom";
import Divider from "@/components/common/Divider";
import useAuth from "@/hooks/useAuth";

const EmailVerifyForm = ({
                   control,
                   register,
                   errors,
                   setError,
                   clearErrors,
                   setValue,
                   watch,
                   reset,
                 }) => {

    const { user, setUser, step, setStep, showLoading, setShowLoading, errorMessage, setErrorMessage, showErrorMessage, setShowErrorMessage, submit } = useContext(AuthWizardContext);

    const {resendOpt} = useAuth();

    const navigate = useNavigate();

    const [count, setCount] = useState(30);
    const [isCounting, setIsCounting] = useState(true);

    useEffect(() => {
        let timer;
        if (isCounting && count > 0) {
            timer = setInterval(() => {
                setCount(prevCount => prevCount - 1);
            }, 1000);
        }

        if (count === 0) {
            setIsCounting(false);
        }

        return () => clearInterval(timer);
    }, [isCounting, count]);


    const onResendOtp = async () => {
        const {companyUid} = user;
        if (companyUid) {
            setCount(30);
            setIsCounting(true);
            await submit(async () => {
                await resendOpt(companyUid)();
            })
        }
    };

  const verified = useMemo(() => {
      return !!user.verified;
  }, [user.verified]);

  return (
    <>
        {!verified && (
            <Row className="g-2 mb-3">
                <WizardInput
                    type="number"
                    label="Verification Code*"
                    name="verificationCode"
                    control={control}
                    register={register}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    setValue={setValue}
                    formGroupProps={{ className: 'mb-3' }}
                    formControlProps={{
                        ...register('verificationCode', {
                            required: 'Verification Code field is required'
                        })
                    }}
                />
                <Button disabled={showLoading} type="submit" color="primary" className="px-5 my-3">
                    Verify
                </Button>
                <Divider className="mx-0">If you don't receive OTP</Divider>
                <Button
                    disabled={showLoading || isCounting}
                    color="secondary"
                    variant="secondary"
                    className="px-5 my-3"
                    onClick={onResendOtp}
                >
                    {isCounting ? `Resend OTP in ${count} (s)` : `Resend OTP`}
                </Button>
            </Row>
        )}
        {verified && (
            <Row className="g-2 mb-3">
                <Col className="text-center">
                    <div className="wizard-lottie-wrapper">
                        <div className="wizard-lottie mx-auto">
                            <Lottie animationData={celebration} loop={true} />
                        </div>
                    </div>
                    <h4 className="mb-1">Your account is all set!</h4>
                    <p className="fs-9">Now you can access to your account</p>
                    <Button color="primary" className="px-5 my-3" onClick={() => {
                        navigate('/auth/login');
                    }}>
                        Login
                    </Button>
                </Col>
            </Row>
        )}
    </>
  );
};

EmailVerifyForm.propTypes = {
    control: PropTypes.any,
    register: PropTypes.func.isRequired,
    errors: PropTypes.object,
    setError: PropTypes.func,
    clearErrors: PropTypes.func,
    setValue: PropTypes.func,
    watch: PropTypes.func,
  reset: PropTypes.func.isRequired
};

export default EmailVerifyForm;
