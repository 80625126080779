import {useEffect, useRef} from 'react';
import {execute} from "@/utils/common";
import PropTypes from "prop-types";

const usePostCallback = (...callbacks) => {
    const called = useRef(false);
    useEffect(() => {
        if (!called.current) {
            called.current = true;
            for (const callback of callbacks || []) {
                (async () => execute(callback))();
            }
        }
    }, [callbacks])
};

usePostCallback.propTypes = {
    callbacks: PropTypes.array,
};

export default usePostCallback;
