import React from 'react';
import {Form} from 'react-bootstrap';
import RequiredSign from "@/components/extra/RequiredSign";
import {execute} from "@/utils/common";

const FormTextInput = ({register, errors, accessor, title, value, callback, placeholder = '', required = false, disabled = false}) => {
    return (
        <Form.Group>
            <Form.Label>
                {title}: {required && <RequiredSign/>}
            </Form.Label>
            <Form.Control
                {...register(accessor)}
                disabled={disabled}
                type="text"
                value={value || ''}
                onChange={(e) => execute(callback, e.target.value)}
                isInvalid={!!errors[accessor]}
                placeholder={placeholder}
            />
            <Form.Control.Feedback type="invalid"
                                   className={errors[accessor]?.message ? 'block' : 'hidden'}>
                {errors[accessor]?.message}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default FormTextInput;
