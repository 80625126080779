import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AuthWizardContext } from '@/context/Context';
import {execute} from "@/utils/common";

const AuthWizardProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [step, setStep] = useState(1);
  const [showLoading, setShowLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const submit = async (callback) => {
    try {
      setErrorMessage(null);
      setShowErrorMessage(false);
      setShowLoading(true)

      await execute(callback);

      setErrorMessage(null);
      setShowErrorMessage(false);
    } catch (error) {
      setErrorMessage(error?.message);
      setShowErrorMessage(true);
      // const timeoutId = setTimeout(() => {
      //   clearTimeout(timeoutId);
      //   setShowErrorMessage(false);
      // }, 3000);
    } finally {
      setShowLoading(false);
    }
  }

  const value = { user, setUser, step, setStep, showLoading, setShowLoading, errorMessage, setErrorMessage, showErrorMessage, setShowErrorMessage, submit };
  return (
    <AuthWizardContext.Provider value={value}>
      {children}
    </AuthWizardContext.Provider>
  );
};

AuthWizardProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthWizardProvider;
