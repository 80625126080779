import React, { useEffect } from "react";

import is from "is_js";
import { BrowserRouter as Router } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { CloseButton } from "@/components/common/Toast";
import SettingsPanel from "@/components/settings-panel/SettingsPanel";
import SettingsToggle from "@/components/settings-panel/SettingsToggle";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import { useAppContext } from "@/Main";
import AppRoutes from "@/routes";

const App = () => {
    // console.log('Environment:', process.env.NODE_ENV);
    // console.log('API URL:', process.env.REACT_APP_API_URL);

    const HTMLClassList = document.getElementsByTagName("html")[0].classList;
    const {
        config: { navbarPosition },
    } = useAppContext();

    useEffect(() => {
        if (is.windows()) {
            HTMLClassList.add("windows");
        }
        if (is.chrome()) {
            HTMLClassList.add("chrome");
        }
        if (is.firefox()) {
            HTMLClassList.add("firefox");
        }
        if (is.safari()) {
            HTMLClassList.add("safari");
        }
    }, [HTMLClassList]);

    // useEffect(() => {
    //     if (navbarPosition === "double-top") {
    //         HTMLClassList.add("double-top-nav-layout");
    //     }
    //     return () => HTMLClassList.remove("double-top-nav-layout");
    // }, [navbarPosition]);

    return (
        <Router basename={process.env.PUBLIC_URL}>
            <AppRoutes />
            {/*<SettingsToggle />*/}
            {/*<SettingsPanel />*/}
            <ToastContainer
                closeButton={CloseButton}
                icon={false}
                position={"bottom-left"}
            />
        </Router>
    );
};

export default App;
