import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import { isIterableArray } from '@/helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from './Flex';
import classNames from 'classnames';
import grabLink from 'youtube-thumbnail-grabber';
import NoImageAvailable from '@/assets/images/live/no-image-available.jpg';
import FormDialog from "@/components/shared/FormDialog";
import ReactPlayer from "react-player/youtube";
import {execute} from "@/utils/common";

const YoutubeThumbnail = ({
  size,
  rounded,
  src,
  name,
  emoji,
  className,
  mediaClass,
  isExact,
  icon,
  style,
  preview = true,
  previewTitle
}) => {
  const formDialogRef = useRef(null);

  const classNames = ['avatar', `avatar-${size}`, className].join(' ');
  const mediaClasses = [
    rounded ? `rounded-${rounded}` : 'rounded',
    mediaClass
  ].join(' ');


  const getYoutubeThumbnail = () => {
    if (src) {
      if (isIterableArray(src)) {
        return (
          <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}
               style={{
                 ...style,
                 cursor: 'pointer'
               }}
               onClick={(e) => {
                   e.preventDefault();
                   if(preview) (async () => execute(formDialogRef?.current?.showDialog))();
               }}
          >
            <div className="w-88 border-right">
              <img src={grabLink(src[0], 'sd') || NoImageAvailable} alt="" />
            </div>
            <div className="w-88 d-flex flex-column">
              <img src={grabLink(src[1], 'sd') || NoImageAvailable} alt="" className="h-50 border-bottom" />
              <img src={grabLink(src[2], 'sd') || NoImageAvailable} alt="" className="h-50" />
            </div>
          </div>
        );
      } else {
        return <img className={mediaClasses}
                    style={{
                      ...style,
                      cursor: 'pointer'
                    }}
                    src={grabLink(src, 'sd') || NoImageAvailable}
                    alt=""
                    onClick={(e) => {
                      e.preventDefault();
                      if(preview) (async () => execute(formDialogRef?.current?.showDialog))();
                    }}/>;
      }
    }

    if (name) {
      return (
        <div className={`avatar-name ${mediaClasses}`}>
          <span>{isExact ? name : name.match(/\b\w/g).join('')}</span>
        </div>
      );
    }

    if (icon) {
      return (
        <Flex className={`avatar-name ${mediaClasses}`}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
      );
    }

    return (
      <div className={`avatar-emoji ${mediaClasses}`}>
        <span role="img" aria-label="Emoji">
          {emoji}
        </span>
      </div>
    );
  };

  return (
      <div className={classNames}>
        {getYoutubeThumbnail()}
        {preview && src && (
            <FormDialog ref={formDialogRef}
                        title={previewTitle || 'Youtube'}
                        size={'lg'}
            >
              <div
                  style={{position: 'relative', paddingTop: '56.25%'}}
              >
                <ReactPlayer url={src} width="100%" height="100%"
                             style={{position: 'absolute', top: 0, left: 0}}
                />
              </div>
            </FormDialog>
        )}
      </div>
  );
};

export const YoutubeThumbnailGroup = ({children, dense, className}) => {
  return (
      <div
          className={classNames(className, 'avatar-group', {
            'avatar-group-dense': dense
          })}
      >
        {children}
      </div>
  );
};

YoutubeThumbnail.propTypes = {
  size: PropTypes.oneOf(['s', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl']),
  rounded: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  name: PropTypes.string,
  emoji: PropTypes.string,
  className: PropTypes.string,
  mediaClass: PropTypes.string,
  isExact: PropTypes.bool,
  icon: PropTypes.string
};

YoutubeThumbnail.defaultProps = {
  size: 'xl',
  rounded: 'circle',
  emoji: '😊',
  isExact: false
};

YoutubeThumbnailGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dense: PropTypes.bool
};

export default YoutubeThumbnail;
