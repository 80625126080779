import {useCallback, useMemo, useState} from 'react';

import useProtectedHttp from "@/hooks/useProtectedHttp";
import useProcessing from "@/hooks/useProcessing";
import PropTypes from "prop-types";
import {isNotEmptyArray} from "@/utils/common";

const useAds = () => {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post } = useProtectedHttp();

    const [ads, setAds] = useState(undefined);
    const [adsPagination, setAdsPagination] = useState({});


    //create
    const createAdsProcessing = useMemo(() => {
        return !!processing?.createAds;
    }, [processing?.createAds]);

    const createAdsErrorMessage = useMemo(() => {
        return errorMessage?.createAds;
    }, [errorMessage?.createAds]);

    const createAds = useCallback((data, inSilentMode = false) => {
        return process('createAds', async () => {
            try {
                if(!inSilentMode) setAds(undefined);
                const result = await post(`/v1/ads/create`, data);
                setAds(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);


    //search
    const searchAdsProcessing = useMemo(() => {
        return !!processing?.searchAds;
    }, [processing?.searchAds]);

    const searchAdsErrorMessage = useMemo(() => {
        return errorMessage?.searchAds;
    }, [errorMessage?.searchAds]);

    const searchAds = useCallback( (data = {}, options = {page: 0, size: 10, sort: ''}, inSilentMode = false) => {
        return process('searchAds', async () => {
            try {
                const {page, size, sort} = options || {};
                if(!inSilentMode) setAdsPagination({});
                const result = size > 0 ? await post(`/v1/ads/search?page=${page}&size=${size}&sort=${sort}`, data)
                    : await get(`/v1/ads`);
                setAdsPagination(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post, get]);


    //get
    const getAdsProcessing = useMemo(() => {
        return !!processing?.getAds;
    }, [processing?.getAds]);

    const getAdsErrorMessage = useMemo(() => {
        return errorMessage?.getAds;
    }, [errorMessage?.getAds]);

    const getAds = useCallback((id, options = {includes: []}, inSilentMode = false) => {
        return process('getAds', async () => {
            try {
                const {includes} = options || {};
                if(!inSilentMode) setAds(undefined);
                const result = await get(`/v1/ads/${id}?includes=${(includes || []).join(',')}`);
                console.log('getAds', result);
                setAds(result);

                return Promise.resolve(result?.ads);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, get]);


    //delete
    const deleteAdsProcessing = useMemo(() => {
        return !!processing?.deleteAds;
    }, [processing?.deleteAds]);

    const deleteAdsErrorMessage = useMemo(() => {
        return errorMessage?.deleteAds;
    }, [errorMessage?.deleteAds]);

    const deleteAds = useCallback((data, inSilentMode = false) => {
        return process('deleteAds', async () => {
            try {
                if(!inSilentMode) setAds(undefined);
                const result = await post(`/v1/ads/delete`, data);
                setAds(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);


    //update
    const updateAdsProcessing = useMemo(() => {
        return !!processing?.updateAds;
    }, [processing?.updateAds]);

    const updateAdsErrorMessage = useMemo(() => {
        return errorMessage?.updateAds;
    }, [errorMessage?.updateAds]);

    const updateAds = useCallback((data, options = {updateFields: []}, inSilentMode = false) => {
        return process('updateAds', async () => {
            try {
                const {id} = data || {};
                const {updateFields} = options || {};
                const result = isNotEmptyArray(updateFields) ? await post(`/v1/ads/${id}/patch`, {data, updateFields}) : await post(`/v1/ads/${id}/update`, data);
                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);

    return {
        createAdsProcessing, createAdsErrorMessage, createAds,
        searchAdsProcessing, searchAdsErrorMessage, searchAds, adsPagination,
        getAdsProcessing, getAdsErrorMessage, getAds, ads,
        deleteAdsProcessing, deleteAdsErrorMessage, deleteAds,
        updateAdsProcessing, updateAdsErrorMessage, updateAds,
    };
};

useAds.propTypes = {
    processing: PropTypes.shape({
        createAds: PropTypes.bool,
        searchAds: PropTypes.bool,
        getAds: PropTypes.bool,
        deleteAds: PropTypes.bool,
        updateAds: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        createAds: PropTypes.string,
        searchAds: PropTypes.string,
        getAds: PropTypes.string,
        deleteAds: PropTypes.string,
        updateAds: PropTypes.bool,
    }),
};

export default useAds;
