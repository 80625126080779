import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from "prop-types";
import {execute} from "@/utils/common";

const useInterval = (callback, delay = 1.0, timeout = 10.0) => {
    const [isActive, setIsActive] = useState(false);
    const callbackRef = useRef(() => {});
    const intervalIdRef = useRef(0);
    const intervalCountRef = useRef(0);

    // Remember the latest callback.
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        const tick = () => {
            intervalCountRef.current++;
            if(intervalCountRef.current > Math.ceil(timeout/delay)){
                setIsActive(false);
            }
            (async () => execute(callbackRef.current))();
        }

        if (isActive) {
            intervalIdRef.current = setInterval(tick, delay * 1000);
            return () => clearInterval(intervalIdRef.current);
        }
    }, [delay, isActive, timeout]);

    const start = useCallback((reset = true) => {
        if(reset) intervalCountRef.current = 0;
        setIsActive(true);
        (async () => execute(callbackRef.current))();
    }, []);

    const stop = useCallback(() => {
        setIsActive(false);
    }, []);

    return {start, stop, isActive};
}

useInterval.propTypes = {
    callback: PropTypes.func,
    delay: PropTypes.number,
    timeout: PropTypes.number,
};


export default useInterval;
