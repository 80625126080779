import React, {forwardRef, useCallback, useImperativeHandle, useMemo} from 'react';
import {Col, Row} from 'react-bootstrap';
import useUser from "@/hooks/biz/useUser";
import FormErrorMessage from "@/components/shared/FormErrorMessage";

const BulkDeleteUserForm = forwardRef((props, ref) => {
    const selectedData = useMemo(() => {
        return props?.selectedData || [];
    }, [props?.selectedData])

    const {
        deleteUserProcessing, deleteUserErrorMessage, deleteUser,
    } = useUser();

    const errorMessage = useMemo(() => {
        return deleteUserErrorMessage;
    }, [deleteUserErrorMessage])


    const onSubmit = useCallback(() => {
        if (selectedData.length > 0) {
            return deleteUser(selectedData?.map(item => item.id) || []);
        }
    }, [selectedData, deleteUser]);

    useImperativeHandle(ref, () => ({
        submit: onSubmit,
    }), [onSubmit]);


    return (
        <Row className="g-3">
            <Col lg={12}>
                <FormErrorMessage errorMessage={errorMessage}/>
                <span>{`Bạn có chắc chắn muốn xoá ${selectedData?.length || 0} Người dùng đã chọn không?`}</span>
            </Col>
        </Row>
    );
});

export default BulkDeleteUserForm;
