import {useCallback, useEffect, useMemo} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import useHttp from "@/hooks/useHttp";
import useAppState from "@/hooks/useAppState";
import useProcessing from "@/hooks/useProcessing";
import PropTypes from "prop-types";

const useAuth = () => {
    const { processing, errorMessage, process } = useProcessing();
    const { state, dispatch, sharedRef } = useAppState();
    const navigate = useNavigate();
    const location = useLocation();
    const { post } = useHttp();

    const auth = useMemo(() => {
        return state?.auth;
    }, [state?.auth])

    const setAuthState = useCallback(({action, payload, store}) => {
        return dispatch({type: action, payload: {auth: payload}, store: store});
    }, [dispatch]);

    const navigateToLogin = useCallback(() => {
        if(location.pathname !== '/' && location.pathname !== '/auth/login' && location.pathname !== '/auth/logout') {
            navigate(`/auth/login?redirect=${location.pathname}`);
        } else {
            navigate(`/auth/login`);
        }
    }, [location.pathname, navigate])

    const loginProcessing = useMemo(() => {
        return !!processing?.login;
    }, [processing?.login]);

    const loginErrorMessage = useMemo(() => {
        return errorMessage?.login;
    }, [errorMessage?.login]);

    const login = useCallback(async (email, password) => {
        return process('login', async () => {
            try {
                const result = await post(`/v1/auth/login`, {email: email, password: password});
                const {jwt, user} = result || {};
                setAuthState({action: 'login', payload: {jwt: jwt, user: user || {email: email}}, store: 'auth.jwt'});
                return Promise.resolve({jwt: jwt, user: user || {email: email}});
            } catch (error) {
                return Promise.reject(error);
            }
        })
    }, [setAuthState, post, process]);


    const logoutProcessing = useMemo(() => {
        return !!processing?.logout;
    }, [processing?.logout]);

    const logoutErrorMessage = useMemo(() => {
        return errorMessage?.logout;
    }, [errorMessage?.logout]);

    const logout = useCallback((redirect = true) => {
        return process('logout', async () => {
            try {
                setAuthState({action: 'logout', payload: {}, store: 'auth.jwt'});
                if(redirect){
                    navigateToLogin();
                }
                return Promise.resolve({});
            } catch (error) {
                return Promise.reject(error);
            }
        })

    }, [setAuthState, process, navigateToLogin]);


    const refreshTokenProcessing = useMemo(() => {
        return !!processing?.refreshToken;
    }, [processing?.refreshToken]);

    const refreshTokenErrorMessage = useMemo(() => {
        return errorMessage?.refreshToken;
    }, [errorMessage?.refreshToken]);

    const refreshToken = useCallback(async () => {
        if(!auth?.jwt?.refreshToken) return;
        return process('refreshToken', async () => {
            try {
                const result = await post(`/v1/auth/newToken`, {refreshToken: auth?.jwt?.refreshToken});
                const {jwt, user} = result || {};
                setAuthState({action: 'refreshToken', payload: {jwt: jwt, user: user || {email:  auth?.user?.email}}, store: 'auth.jwt'});
                return Promise.resolve({jwt: jwt, user: user || {email:  auth?.user?.email}});
            } catch (error) {
                await logout();
                return Promise.reject(error);
            }
        })
    }, [auth?.jwt?.refreshToken, auth?.user?.email, setAuthState, logout, post, process]);

    const registerProcessing = useMemo(() => {
        return !!processing?.register;
    }, [processing?.register]);

    const registerErrorMessage = useMemo(() => {
        return errorMessage?.register;
    }, [errorMessage?.register]);

    const register = useCallback(async (data) => {
        return process('register', async () => {
            try {
                const result = await post(`/v1/sys_users/register`, data);
                const {user} = result || {};
                setAuthState({action: 'register', payload: {user: user || data}, store: 'auth.user'});
                return Promise.resolve({user: user || data});
            } catch (error) {
                return Promise.reject(error);
            }
        })
    }, [setAuthState, post, process]);


    const sendOtpProcessing = useMemo(() => {
        return !!processing?.register;
    }, [processing?.register]);

    const sendOtpErrorMessage = useMemo(() => {
        return errorMessage?.sendOtp;
    }, [errorMessage?.sendOtp]);

    const sendOtp = useCallback(async (email) => {
        return process('sendOtp', async () => {
            try {
                const result = await post(`/v1/sys_users/otp`, {email: email});
                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        })
    }, [post, process]);

    useEffect(() => {
        if(!sharedRef.current['refreshTokenInterval']){
            const clearRefreshTokenInterval = () => {
                clearInterval(sharedRef.current['refreshTokenInterval']);
                sharedRef.current['refreshTokenInterval'] = undefined;
            }
            sharedRef.current['refreshTokenInterval'] = setInterval(() => {
                return refreshToken();
            }, 10 * 60 * 1000); // Refresh every 10 minutes

            return () =>  clearRefreshTokenInterval();
        }
    }, [refreshToken, sharedRef]);

    return {
        auth,
        loginProcessing, loginErrorMessage, login,
        logoutProcessing, logoutErrorMessage, logout,
        refreshTokenProcessing, refreshTokenErrorMessage, refreshToken,
        registerProcessing, registerErrorMessage, register,
        sendOtpProcessing, sendOtpErrorMessage, sendOtp,
    };
};

useAuth.propTypes = {
    processing: PropTypes.shape({
        login: PropTypes.bool,
        logout: PropTypes.bool,
        refreshToken: PropTypes.bool,
        register: PropTypes.bool,
        sendOtp: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        login: PropTypes.string,
        logout: PropTypes.string,
        refreshToken: PropTypes.string,
        register: PropTypes.string,
        sendOtp: PropTypes.string,
    }),
};


export default useAuth;
