import React, {forwardRef, useCallback, useImperativeHandle, useMemo} from 'react';
import {Col, Row} from 'react-bootstrap';
import useProvince from "@/hooks/biz/useProvince";
import FormErrorMessage from "@/components/shared/FormErrorMessage";

const BulkDeleteProvinceForm = forwardRef((props, ref) => {
    const selectedData = useMemo(() => {
        return props?.selectedData || [];
    }, [props?.selectedData])

    const {
        deleteProvinceProcessing, deleteProvinceErrorMessage, deleteProvince,
    } = useProvince();

    const errorMessage = useMemo(() => {
        return deleteProvinceErrorMessage;
    }, [deleteProvinceErrorMessage])


    const onSubmit = useCallback(() => {
        if (selectedData.length > 0) {
            return deleteProvince(selectedData?.map(item => item.id) || []);
        }
    }, [selectedData, deleteProvince]);

    useImperativeHandle(ref, () => ({
        submit: onSubmit,
    }), [onSubmit]);


    return (
        <Row className="g-3">
            <Col lg={12}>
                <FormErrorMessage errorMessage={errorMessage}/>
                <span>{`Bạn có chắc chắn muốn xoá ${selectedData?.length || 0} Tỉnh/Thành phố đã chọn không?`}</span>
            </Col>
        </Row>
    );
});

export default BulkDeleteProvinceForm;
