import React, {useCallback, useMemo, useState} from 'react';
import {Button, Card, Col, FormControl, InputGroup, Row} from 'react-bootstrap';
import AdvanceTablePagination from '@/components/common/advance-table/AdvanceTablePagination';
import PropTypes from "prop-types";
import ViewModeNavBar, {ViewMode} from "@/components/common/advance-table/ViewModeNavBar";
import TreeView, {flattenTree} from "react-accessible-treeview";
import {FaRegSquare , FaRegCheckSquare, FaRegMinusSquare, FaCheck} from "react-icons/fa";
import { PiCheckBold, PiWarningBold } from "react-icons/pi";
import { IoMdArrowDropright, IoMdCheckmark, IoMdWarning, IoMdClose, IoMdCloseCircleOutline, IoMdCloseCircle, IoMdFastforward } from "react-icons/io";
import cx from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AdvanceSearchBox from "@/components/common/advance-table/AdvanceSearchBox";
import IconButton from "@/components/common/IconButton";
import {execute, exportToJsonFile, merge, mergeStringCallback} from "@/utils/common";
import {buildCardImageResultTreeData} from "@/components/app/projects/utils";
import Loading from "@/components/extra/Loading";

const ArrowIcon = ({ isOpen, className }) => {
    const baseClass = "arrow";
    const classes = cx(
        baseClass,
        { [`${baseClass}--closed`]: !isOpen },
        { [`${baseClass}--open`]: isOpen },
        className
    );
    return <IoMdArrowDropright color="748194" className={classes} />;
};

const CheckBoxIcon = ({ variant, ...rest }) => {
    switch (variant) {
        case "all":
            return <FaRegCheckSquare {...rest} color={'#2c7be5'} />;
        case "none":
            return <FaRegSquare {...rest} color={'#748194'} />;
        case "some":
            return <FaRegMinusSquare {...rest} color={'#2c7be5'} />;
        default:
            return null;
    }
};

const ResultIcon = ({ variant, ...rest }) => {
    switch (variant) {
        case "PASS":
        case "Pass":
            return <IoMdCheckmark {...rest} color={'#2c7be5'} />;
        case "FAIL":
        case "Error":
            return <IoMdClose {...rest} color={'#e63757'} />;
        case "Warning":
            return <IoMdCloseCircleOutline {...rest} color={'#fd7e14'} />;
        case "SKIP":
            return <IoMdFastforward {...rest} color={'#2c7be5'} />;
        case "INC":
        case "Inconclusive":
            return <IoMdWarning {...rest} color={'#fd7e14'} />;
        default:
            return null;
    }
};

const isHighlightNode = (data) => {
    const {result, expandable} = data || {};
    if(!expandable || !result) return false;

    switch (result) {
        case "FAIL":
        case "Error":
        case "Warning":
        case "SKIP":
        case "INC":
        case "Inconclusive":
            return true;
        default:
            return false;
    }
}

const isExpandedNode = (data) => {
    const {result, expandable} = data || {};
    if(!expandable || !result) return false;

    switch (result) {
        case "FAIL":
        case "Error":
        case "Warning":
        case "SKIP":
        case "INC":
        case "Inconclusive":
            return true;
        default:
            return false;
    }
}

const isExpandableNode = (data) => {
    const {result, expandable} = data || {};
    return expandable === true;
}

const getDefaultNodeIds = (tree) => {
    const expandedNodeIds = {};
    const selectedNodeIds = {};
    const addExpandedNodeId = (parentNodeIds, node) => {
        const isExpanded = isExpandedNode(node.metadata);
        const isExpandable = isExpandableNode(node.metadata);

        if(isExpanded){
            for (const parentNodeId of parentNodeIds) {
                expandedNodeIds[parentNodeId] = true;
            }
            // expandedNodeIds[node.id] = true;
            selectedNodeIds[node.id] = true;
        }

        if(node.children && node.children.length > 0 && (!isExpandable || (isExpandable && isExpanded))){
            for (const child of node.children) {
                addExpandedNodeId([...parentNodeIds, node.id], child);
            }
        }
    }

    if(tree && tree.length > 0){
        for (const branch of tree) {
            addExpandedNodeId([], branch);
        }
    }

    return { expandedIds: Object.keys(expandedNodeIds), selectedIds: Object.keys(selectedNodeIds)};
}

function getRandomValue(arr) {
    if (arr.length === 0) return null;
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
}


const CardImageResultTreeHeader = ({selectedRowIds, pageSize, setPageSize, viewMode, onViewModeChange, filter, setFilter, onExport}) => {
    return (
        <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <Row className="flex-between-center">
                    <Col xs="auto">
                        <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Card Image Result</h5>
                    </Col>
                    <Col xs="auto">
                        <AdvanceSearchBox
                            className="input-search-width"
                            filter={filter}
                            setFilter={setFilter}
                            placeholder="Enter Test Name"
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
                <Row className="flex-between-center">
                    <Col xs="auto" className={'d-flex align-items-center justify-content-between'} style={{ gap: '8px' }}>
                    </Col>
                    <Col xs="auto" className={'d-flex align-items-center justify-content-between'} style={{ gap: '8px' }}>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="external-link-alt"
                            transform="shrink-3"
                            className="mx-0"
                            iconAlign="middle"
                            onClick={() => {
                                if (onExport) onExport();
                            }}
                        >
                          <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                            Export
                          </span>
                        </IconButton>
                        {onViewModeChange && <ViewModeNavBar viewMode={viewMode} onViewModeChange={onViewModeChange}/>}
                    </Col>
                </Row>
            </Col>
        </Row>
);
};

CardImageResultTreeHeader.propTypes = {
    selectedRowIds: PropTypes.object,
    viewMode: PropTypes.oneOf([ViewMode.TREE, ViewMode.TABLE]),
    onViewModeChange: PropTypes.func,
    filter: PropTypes.object,
    setFilter: PropTypes.func
};


const CardImageResultTree = ({data, viewMode, onViewModeChange, filter, setFilter}) => {
    const [pageSize, setPageSize] = useState(300);
    const [pageIndex, setPageIndex] = useState(0);

    const pageCount = Math.ceil(data.length / pageSize);

    const [selectedItems, setSelectedItems] = useState([]);

    // const [pagingData, setPagingData] = useState();

    const gotoPage = useCallback((page) => {
        if (page < 0 || page >= pageCount) return;
        setPageIndex(page);
    }, [pageCount])

    const getPagingData = useCallback(() => {
        const startIndex = pageIndex * pageSize;
        const endIndex = startIndex + pageSize;
        if (startIndex >= data.length) return [];
        return data.slice(startIndex, endIndex > data.length ? data.length : endIndex);
    }, [data, pageSize, pageIndex])


    const exportTreeDataToJsonFile = useCallback(() => {
        exportToJsonFile(data.map((item) => item.toJsonObject()), 'card_image_result.json');
    }, [data])


    const defaultNodeIds = useMemo(() => {
        return getDefaultNodeIds(buildCardImageResultTreeData(data))
    }, [data]);

    const pagingData = useMemo(() => {
        return getPagingData()
    }, [getPagingData]);

    const pagingTreeData = useMemo(() => {
        return flattenTree({
            name: "",
            children: buildCardImageResultTreeData(pagingData),
        });
    }, [pagingData]);

    const isEmptyPagingTreeData = useMemo(() => {
        return !pagingData || pagingData.length === 0;
    }, [pagingData])

    return (
        <Card className="mb-3">
            <Card.Header>
                <CardImageResultTreeHeader table pageSize={pageSize} setPageSize={setPageSize}
                                    viewMode={viewMode} onViewModeChange={onViewModeChange}
                                    filter={filter} setFilter={setFilter} onExport={exportTreeDataToJsonFile}/>
            </Card.Header>
            <Card.Body className="px-3 py-0 directory">
                {!isEmptyPagingTreeData && (
                    <TreeView
                        data={pagingTreeData}
                        defaultExpandedIds={defaultNodeIds.expandedIds}
                        // defaultSelectedIds={defaultNodeIds.selectedIds}
                        multiSelect
                        propagateSelect
                        propagateSelectUpwards
                        togglableSelect
                        nodeRenderer={({
                                           element,
                                           isBranch,
                                           isExpanded,
                                           isSelected,
                                           isHalfSelected,
                                           getNodeProps,
                                           level,
                                           handleSelect,
                                           handleExpand,
                                       }) => (
                            <div
                                {...(isHighlightNode(element.metadata) ? merge({className: 'tree-node--highlight'}, getNodeProps({onClick: handleExpand}) , mergeStringCallback) : getNodeProps({onClick: handleExpand}))}
                                style={{marginLeft: 40 * (level - 1)}}
                            >
                                {isBranch && <ArrowIcon isOpen={isExpanded}/>}
                                <CheckBoxIcon
                                    className="checkbox-icon"
                                    onClick={(e) => {
                                        handleSelect(e);
                                        e.stopPropagation();
                                    }}
                                    variant={
                                        isHalfSelected ? "some" : isSelected ? "all" : "none"
                                    }
                                />
                                {element.metadata?.result && (
                                    <ResultIcon
                                        className="checkbox-icon"
                                        variant={element.metadata?.result}
                                        // variant={getRandomValue(['PASS', 'FAIL', 'INC', 'SKIP'])}
                                    />
                                )}

                                <span className={`name`}>
                                    {element.name}
                            </span>
                            </div>
                        )
                        }
                    />
                )}
            </Card.Body>
            <Card.Footer>
            {pageCount > 1 && (
                    <AdvanceTablePagination canPreviousPage={pageIndex > 0} canNextPage={pageIndex < pageCount - 1}
                                            previousPage={pageIndex - 1} nextPage={pageIndex + 1} pageCount={pageCount}
                                            pageIndex={pageIndex} gotoPage={(page) => gotoPage(page)}/>
                )}
            </Card.Footer>
        </Card>
    );
};

CardImageResultTree.propTypes = {
    data: PropTypes.array,
    viewMode: PropTypes.oneOf([ViewMode.TREE, ViewMode.TABLE]),
    onViewModeChange: PropTypes.func,
    filter: PropTypes.object,
    setFilter: PropTypes.func
};

export default CardImageResultTree;
