import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
} from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import { courseTypes } from "@/components/app/courses/CourseManagement";
import FormErrorMessage from "@/components/shared/FormErrorMessage";
import FormSelectInput from "@/components/shared/FormSelectInput";
import FormTextInput from "@/components/shared/FormTextInput";
import useCourse from "@/hooks/biz/useCourse";
import usePostCallback from "@/hooks/usePostCallback";

const CreateOrEditCourseForm = forwardRef((props, ref) => {
    const { data } = props || {};
    const validationSchema = yup.object().shape({
        courseName: yup.string().required("Tên Khoá học không được để trống."),
        courseType: yup.number().min(1, "Loại Khoá học không được để trống."),
        courseCode: yup.string().required("Mã Khoá học không được để trống."),
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...data,
        },
    });

    const {
        setValue,
        register,
        formState: { errors },
        trigger,
        getValues,
    } = methods;

    const handleValueChange = useCallback(
        accessor => value => {
            setValue(accessor, value, { shouldValidate: true });
            // await trigger();
        },
        [setValue],
    );

    const {
        createCourseProcessing,
        createCourseErrorMessage,
        createCourse,
        updateCourseProcessing,
        updateCourseErrorMessage,
        updateCourse,
    } = useCourse();

    const disabled = useMemo(() => {
        return createCourseProcessing || updateCourseProcessing;
    }, [createCourseProcessing, updateCourseProcessing]);

    const errorMessage = useMemo(() => {
        return createCourseErrorMessage || updateCourseErrorMessage;
    }, [createCourseErrorMessage, updateCourseErrorMessage]);

    const onSubmit = useCallback(() => {
        const hasError = Object.keys(errors).length > 0;
        if (!hasError) {
            const values = getValues();
            return values?.id && values?.id > 0
                ? updateCourse(values)
                : createCourse(values);
        } else {
            return { error: errors };
        }
    }, [createCourse, errors, getValues, updateCourse]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
        }),
        [onSubmit],
    );

    usePostCallback(trigger);

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Row className="g-3">
                    <Col lg={12}>
                        <FormErrorMessage errorMessage={errorMessage} />
                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"courseName"}
                            title={"Tên Khoá học"}
                            value={getValues("courseName")}
                            callback={handleValueChange("courseName")}
                            placeholder={"Nhập Tên Khoá học"}
                            required={true}
                            disabled={disabled}
                        />
                        <FormSelectInput
                            register={register}
                            errors={errors}
                            accessor={"courseType"}
                            title={"Loại Khoá học"}
                            value={getValues("courseType")}
                            nonValue={0}
                            defaultValue={1}
                            refData={courseTypes}
                            refAccessor={"name"}
                            callback={handleValueChange("courseType")}
                            placeholder={"Loại Khoá học"}
                            required={true}
                            disabled={disabled}
                        />
                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"courseCode"}
                            title={"Mã Khoá học"}
                            value={getValues("courseCode")}
                            callback={handleValueChange("courseCode")}
                            placeholder={"Nhập Mã Khoá học"}
                            required={true}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
});

export default CreateOrEditCourseForm;
