import {useCallback, useEffect, useMemo, useState} from 'react';

import useProtectedHttp from "@/hooks/useProtectedHttp";
import useProcessing from "@/hooks/useProcessing";
import PropTypes from "prop-types";

const useProduct = (projectId)=> {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post, put, del, upload, download } = useProtectedHttp();

    const [step, setStep] = useState(1);
    const [applications, setApplications] = useState([]);
    const [cardImages, setCardImages] = useState([]);
    const [cardImageIds, setCardImageIds] = useState([]);
    const [cardImageResult, setCardImageResult] = useState([]);

    const uploadCardImageProcessing = useMemo(() => {
        return !!processing?.uploadCardImage;
    }, [processing?.uploadCardImage]);

    const uploadCardImageErrorMessage = useMemo(() => {
        return errorMessage?.uploadCardImage;
    }, [errorMessage?.uploadCardImage]);

    const formDataOfCardImageFiles = (files) => {
        const formData = new FormData();
        Array.from(files).forEach((file, index) => {
            formData.append(`cardImages[${index}].image`, file);
        });
        return formData;
    }

    const uploadCardImage = useCallback((data) => {
        return process('uploadCardImage', async () => {
            try {
                setCardImageIds([]);
                const result = await upload(`/project/${projectId}/card-image/upload`, data);
                const {cardImagesIds} = result;
                setCardImageIds(cardImagesIds || []);
                return Promise.resolve(cardImagesIds || []);
            } catch (error) {
                return Promise.reject(error);
            }
        })
    }, [process, upload, projectId]);

    const clearCardImageIds = () => {
        setCardImageIds([]);
    }

    const getApplicationsProcessing = useMemo(() => {
        return !!processing?.getApplications;
    }, [processing?.getApplications]);

    const getApplicationsErrorMessage = useMemo(() => {
        return errorMessage?.getApplications;
    }, [errorMessage?.getApplications]);

    const getApplications = useCallback(() => {
        return process('getApplications', async () => {
            try {
                setApplications([]);
                const result = await get(`/project/${projectId}/application`);
                const {applications} = result;
                setApplications(applications || []);
                return Promise.resolve(applications || []);
            } catch (error) {
                return Promise.reject(error);
            }
        })
    }, [process, get, projectId]);

    const getCardImagesProcessing = useMemo(() => {
        return !!processing?.getCardImages
    }, [processing?.getCardImages]);

    const getCardImagesErrorMessage = useMemo(() => {
        return errorMessage?.getCardImages;
    }, [errorMessage?.getCardImages]);

    const getCardImages = useCallback((reset = true) => {
        return process('getCardImages', async () => {
            try {
                if(reset) setCardImages([]);
                const result = await get(`/project/${projectId}/card-image`);
                const {cardImages} = result;
                setCardImages(cardImages || []);
                return Promise.resolve(cardImages || []);
            } catch (error) {
                return Promise.reject(error);
            }
        })
    }, [process, get, projectId]);


    const validateCardImagesProcessing = useMemo(() => {
        return !!processing?.validateCardImages
    }, [processing?.validateCardImages]);

    const validateCardImagesErrorMessage = useMemo(() => {
        return errorMessage?.validateCardImages;
    }, [errorMessage?.validateCardImages]);

    const validateCardImages = useCallback((cardImageIds = []) => {
        return process('validateCardImages', async () => {
            try {
                const result = await post(`/project/${projectId}/card-image/validation/rerun`, { cardImageUids: cardImageIds });
                const {cardImagesIds} = result;
                return Promise.resolve(cardImagesIds || []);
            } catch (error) {
                return Promise.reject(error);
            }
        })
    }, [process, post, projectId]);


    const getCardImageResultProcessing = useMemo(() => {
        return !!processing?.getCardImageResult
    }, [processing?.getCardImageResult]);

    const getCardImageResultErrorMessage = useMemo(() => {
        return errorMessage?.getCardImageResult;
    }, [errorMessage?.getCardImageResult]);

    const getCardImageResult = useCallback((cardImageId) => {
        return process('getCardImageResult', async () => {
            try {
                setCardImageResult([]);
                const result = await get(`/project/${projectId}/card-image/${cardImageId}/result`);
                const {testStepResult} = result || {};
                setCardImageResult(testStepResult || []);
                return Promise.resolve(testStepResult || []);
            } catch (error) {
                return Promise.reject(error);
            }
        })
    }, [process, get, projectId]);

    return {
        step, setStep,
        uploadCardImageProcessing, uploadCardImageErrorMessage, uploadCardImage, cardImageIds, clearCardImageIds, formDataOfCardImageFiles,
        getApplicationsProcessing, getApplicationsErrorMessage, getApplications, applications,
        getCardImagesProcessing, getCardImagesErrorMessage, getCardImages, cardImages,
        getCardImageResultProcessing, getCardImageResultErrorMessage, getCardImageResult, cardImageResult,
        validateCardImagesProcessing, validateCardImagesErrorMessage, validateCardImages,
    };
};

useProduct.propTypes = {
    processing: PropTypes.shape({
        uploadCardImage: PropTypes.bool,
        getApplications: PropTypes.bool,
        getCardImages: PropTypes.bool,
        getCardImageResult: PropTypes.bool,
        validateCardImages: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        uploadCardImage: PropTypes.string,
        getApplications: PropTypes.string,
        getCardImages: PropTypes.string,
        getCardImageResult: PropTypes.string,
        validateCardImages: PropTypes.string,
    }),
};

export default useProduct;
