import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useTag from "@/hooks/biz/useTag";
import usePostCallback from "@/hooks/usePostCallback";
import FormTextInput from "@/components/shared/FormTextInput";
import FormErrorMessage from "@/components/shared/FormErrorMessage";

const CreateOrEditTagForm = forwardRef((props, ref) => {
    const { data } = props || {};
    const validationSchema = yup.object().shape({
        tagName: yup.string().required("Tag/Nhãn không được để trống."),
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...data,
        },
    });

    const {
        setValue,
        register,
        formState: { errors },
        trigger,
        getValues,
    } = methods;

    const handleValueChange = useCallback(
        accessor => value => {
            setValue(accessor, value, { shouldValidate: true });
            // await trigger();
        },
        [setValue],
    );

    const {
        createTagProcessing,
        createTagErrorMessage,
        createTag,
        updateTagProcessing,
        updateTagErrorMessage,
        updateTag,
    } = useTag();

    const disabled = useMemo(() => {
        return createTagProcessing || updateTagProcessing;
    }, [createTagProcessing, updateTagProcessing]);

    const errorMessage = useMemo(() => {
        return createTagErrorMessage || updateTagErrorMessage;
    }, [createTagErrorMessage, updateTagErrorMessage]);

    const onSubmit = useCallback(() => {
        const hasError = Object.keys(errors).length > 0;
        if (!hasError) {
            const values = getValues();
            return values?.id && values?.id > 0
                ? updateTag(values)
                : createTag(values);
        } else {
            return { error: errors };
        }
    }, [createTag, errors, getValues, updateTag]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
        }),
        [onSubmit],
    );

    usePostCallback(trigger);

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Row className="g-3">
                    <Col lg={12}>
                        <FormErrorMessage errorMessage={errorMessage} />
                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"tagName"}
                            title={"Tag/Nhãn"}
                            value={getValues("tagName")}
                            callback={handleValueChange("tagName")}
                            placeholder={"Nhập Tag/Nhãn"}
                            required={true}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
});

export default CreateOrEditTagForm;
