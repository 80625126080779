import { createSecureTimedStorage } from 'secure-timed-storage';
import {useCallback} from "react";
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const storage = createSecureTimedStorage({ encryptionKey: SECRET_KEY });

const useStorage = () => {

  const getItem = useCallback( (key) => {
    try{
      return storage.getItem(key);
    } catch (e) {
      return undefined;
    }
  }, []);

  const setItem = useCallback( (key, value) => {
    try{
      return storage.setItem(key, value);
    } catch (e) {
      return undefined;
    }
  }, []);

  const removeItem = useCallback( (key) => {
    try {
      return storage.removeItem(key);
    } catch (e) {
      return undefined;
    }
  }, []);

  return {
    getItem,
    setItem,
    removeItem,
  };
};

export default useStorage;
