import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useQuestion from "@/hooks/biz/useQuestion";
import usePostCallback from "@/hooks/usePostCallback";
import FormErrorMessage from "@/components/shared/FormErrorMessage";
import FormTextInput from "@/components/shared/FormTextInput";
import FormSelectInput from "@/components/shared/FormSelectInput";
import { questionTypes } from "@/components/app/questions/QuestionManagement";
import FormNumberInput from "@/components/shared/FormNumberInput";
import FormAnswerInput from "@/components/shared/FormAnswerInput";
import { fromAnswers } from "@/components/app/questions/utils";

const DeleteQuestionForm = forwardRef((props, ref) => {
    const { data } = props || {};

    const validationSchema = yup.object().shape({
        id: yup.number().moreThan(0, "Câu hỏi không hợp lệ"),
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...data,
            ...fromAnswers(data?.answers),
        },
    });

    const {
        reset,
        setValue,
        register,
        formState: { errors },
        trigger,
        getValues,
    } = methods;

    const handleValueChange = useCallback(
        accessor => value => {
            setValue(accessor, value, { shouldValidate: true });
            (async () => trigger())();
        },
        [setValue],
    );

    const {
        deleteQuestionProcessing,
        deleteQuestionErrorMessage,
        deleteQuestion,
        getQuestionProcessing,
        getQuestionErrorMessage,
        getQuestion,
        question,
    } = useQuestion();

    const disabled = useMemo(() => {
        return true;
    }, []);

    const errorMessage = useMemo(() => {
        return deleteQuestionErrorMessage;
    }, [deleteQuestionErrorMessage]);

    const onSubmit = useCallback(() => {
        const hasError = Object.keys(errors).length > 0;
        if (!hasError) {
            const values = getValues() || {};
            return deleteQuestion([values["id"]]);
        } else {
            return { error: errors };
        }
    }, [deleteQuestion, errors, getValues]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
        }),
        [onSubmit],
    );

    useEffect(() => {
        reset({ ...question, ...fromAnswers(question?.answers) });
        (async () => trigger())();
    }, [question, reset, trigger]);

    useEffect(() => {
        reset({
            ...data,
            ...fromAnswers(data?.answers),
        });
    }, [reset, data]);

    usePostCallback(trigger, () => {
        if (data?.id) return getQuestion(data?.id);
    });

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Row className="g-3">
                    <Col lg={12}>
                        <FormErrorMessage errorMessage={errorMessage} />

                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"title"}
                            title={"Nội dung câu hỏi"}
                            value={getValues("title")}
                            callback={handleValueChange("title")}
                            placeholder={"Nhập Nội dung câu hỏi"}
                            required={true}
                            disabled={disabled}
                        />
                        <Row className="gx-2 gy-3">
                            <Col md="6">
                                <FormSelectInput
                                    register={register}
                                    errors={errors}
                                    accessor={"questionType"}
                                    title={"Loại câu hỏi"}
                                    value={getValues("questionType")}
                                    nonValue={0}
                                    defaultValue={1}
                                    refData={questionTypes}
                                    refAccessor={"name"}
                                    callback={handleValueChange("questionType")}
                                    placeholder={"Loại câu hỏi"}
                                    required={true}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col md="6">
                                <FormNumberInput
                                    register={register}
                                    errors={errors}
                                    accessor={"point"}
                                    title={"Điểm"}
                                    value={getValues("point")}
                                    defaultValue={1}
                                    callback={handleValueChange("point")}
                                    placeholder={"Nhập Điểm"}
                                    required={true}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>

                        <Row className="gx-2 gy-3">
                            <Col md="6">
                                <FormAnswerInput
                                    register={register}
                                    errors={errors}
                                    accessor={"answer1Content"}
                                    title={"Đáp án 1"}
                                    value={getValues("answer1Content")}
                                    callback={handleValueChange(
                                        "answer1Content",
                                    )}
                                    extAccessor={"answer1Correct"}
                                    extTitle={"Sai/Đúng"}
                                    extValue={getValues("answer1Correct")}
                                    extCallback={handleValueChange(
                                        "answer1Correct",
                                    )}
                                    placeholder={"Nhập Đáp án 1"}
                                    required={true}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col md="6">
                                <FormAnswerInput
                                    register={register}
                                    errors={errors}
                                    accessor={"answer2Content"}
                                    title={"Đáp án 2"}
                                    value={getValues("answer2Content")}
                                    callback={handleValueChange(
                                        "answer2Content",
                                    )}
                                    extAccessor={"answer2Correct"}
                                    extTitle={"Sai/Đúng"}
                                    extValue={getValues("answer2Correct")}
                                    extCallback={handleValueChange(
                                        "answer2Correct",
                                    )}
                                    placeholder={"Nhập Đáp án 2"}
                                    required={true}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                        <Row className="gx-2 gy-3">
                            <Col md="6">
                                <FormAnswerInput
                                    register={register}
                                    errors={errors}
                                    accessor={"answer3Content"}
                                    title={"Đáp án 3"}
                                    value={getValues("answer3Content")}
                                    callback={handleValueChange(
                                        "answer3Content",
                                    )}
                                    extAccessor={"answer3Correct"}
                                    extTitle={"Sai/Đúng"}
                                    extValue={getValues("answer3Correct")}
                                    extCallback={handleValueChange(
                                        "answer3Correct",
                                    )}
                                    placeholder={"Nhập Đáp án 3"}
                                    required={true}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col md="6">
                                <FormAnswerInput
                                    register={register}
                                    errors={errors}
                                    accessor={"answer4Content"}
                                    title={"Đáp án 4"}
                                    value={getValues("answer4Content")}
                                    callback={handleValueChange(
                                        "answer4Content",
                                    )}
                                    extAccessor={"answer4Correct"}
                                    extTitle={"Sai/Đúng"}
                                    extValue={getValues("answer4Correct")}
                                    extCallback={handleValueChange(
                                        "answer4Correct",
                                    )}
                                    placeholder={"Nhập Đáp án 4"}
                                    required={true}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
});

DeleteQuestionForm.displayName = "DeleteQuestionForm";

export default DeleteQuestionForm;
