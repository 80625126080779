import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useUser from "@/hooks/biz/useUser";
import usePostCallback from "@/hooks/usePostCallback";
import FormErrorMessage from "@/components/shared/FormErrorMessage";
import FormTextInput from "@/components/shared/FormTextInput";
import FormDateInput from "@/components/shared/FormDateInput";
import { toDate } from "@/utils/common";
import FormSelectInput from "@/components/shared/FormSelectInput";
import useProvince from "@/hooks/biz/useProvince";

const DeleteUserForm = forwardRef((props, ref) => {
    const { data } = props || {};

    const validationSchema = yup.object().shape({
        id: yup.number().moreThan(0, "Người dùng không hợp lệ"),
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...data,
        },
    });

    const {
        setValue,
        register,
        formState: { errors },
        trigger,
        getValues,
    } = methods;

    const handleValueChange = useCallback(
        accessor => value => {
            setValue(accessor, value, { shouldValidate: true });
            // await trigger();
        },
        [setValue],
    );

    const { deleteUserProcessing, deleteUserErrorMessage, deleteUser } =
        useUser();

    const disabled = useMemo(() => {
        return true;
    }, []);

    const errorMessage = useMemo(() => {
        return deleteUserErrorMessage;
    }, [deleteUserErrorMessage]);

    const onSubmit = useCallback(() => {
        const hasError = Object.keys(errors).length > 0;
        if (!hasError) {
            const values = getValues() || {};
            return deleteUser([values["id"]]);
        } else {
            return { error: errors };
        }
    }, [deleteUser, errors, getValues]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
        }),
        [onSubmit],
    );

    const {
        searchProvince,
        provincesPagination: { content: provinces },
    } = useProvince();

    usePostCallback(trigger, () =>
        searchProvince({}, { page: 0, size: 0, sort: "" }),
    );

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Row className="g-3">
                    <Col lg={12}>
                        <FormErrorMessage errorMessage={errorMessage} />
                        <Row className="gx-2 gy-3">
                            <Col md="6">
                                <FormTextInput
                                    register={register}
                                    errors={errors}
                                    accessor={"fullName"}
                                    title={"Họ và tên"}
                                    value={getValues("fullName")}
                                    callback={handleValueChange("fullName")}
                                    placeholder={"Nhập Họ và Tên"}
                                    required={true}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col md="6">
                                <FormTextInput
                                    register={register}
                                    errors={errors}
                                    accessor={"email"}
                                    title={"Email"}
                                    value={getValues("email")}
                                    callback={handleValueChange("email")}
                                    placeholder={"Nhập Địa chỉ email"}
                                    required={true}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                        <Row className="gx-2 gy-3">
                            <Col md="6">
                                <FormTextInput
                                    register={register}
                                    errors={errors}
                                    accessor={"phoneNumber"}
                                    title={"Số điện thoại"}
                                    value={getValues("phoneNumber")}
                                    callback={handleValueChange("phoneNumber")}
                                    placeholder={"Nhập Số điện thoại"}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col md="6">
                                <FormDateInput
                                    register={register}
                                    errors={errors}
                                    accessor={"dateOfBirth"}
                                    title={"Ngày sinh"}
                                    value={toDate(getValues("dateOfBirth"))}
                                    callback={handleValueChange("dateOfBirth")}
                                    placeholder={"Chọn Ngày sinh"}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                        <FormSelectInput
                            register={register}
                            errors={errors}
                            accessor={"provinceId"}
                            title={"Tỉnh/Thành phố"}
                            value={getValues("provinceId")}
                            nonValue={0}
                            defaultValue={0}
                            refData={provinces}
                            refAccessor={"provinceName"}
                            callback={handleValueChange("provinceId")}
                            placeholder={"Tỉnh/Thành phố"}
                            required={true}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
});

export default DeleteUserForm;
