import React from "react";

import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownApp from "./NavbarDropdownApp";
import NavbarDropdownModules from "./NavbarDropdownModules";
import NavbarDropdownPages from "./NavbarDropdownPages";

import NavbarTopNavLink from "@/components/navbar/top/NavbarTopNavLink";
import TopNavbarDropdown from "@/components/navbar/TopNavbarDropdown";
import { flatRoutes } from "@/helpers/utils";
import { useAppContext } from "@/Main";
import {
    appRoutes,
    bizRoutes,
    categoryRoutes,
    documentationRoutes,
    modulesRoutes,
    pagesRoutes,
    rootRoutes,
    systemRoutes,
} from "@/routes/siteMaps";

const NavbarTopDropDownMenus = () => {
    const {
        config: { navbarCollapsed, showBurgerMenu },
        setConfig,
    } = useAppContext();

    const handleDropdownItemClick = () => {
        if (navbarCollapsed) {
            setConfig("navbarCollapsed", !navbarCollapsed);
        }
        if (showBurgerMenu) {
            setConfig("showBurgerMenu", !showBurgerMenu);
        }
    };

    return (
        <>
            <TopNavbarDropdown routes={bizRoutes} />
            <TopNavbarDropdown routes={categoryRoutes} />
            <TopNavbarDropdown routes={systemRoutes} />

            {/*<NavbarDropdown title="app">*/}
            {/*    <NavbarDropdownApp items={appRoutes.children} />*/}
            {/*</NavbarDropdown>*/}

            {/*<NavbarDropdown title="pages">*/}
            {/*  <NavbarDropdownPages items={pagesRoutes.children} />*/}
            {/*</NavbarDropdown>*/}
            {/*<NavbarDropdown title="modules">*/}
            {/*    <NavbarDropdownModules items={modulesRoutes.children} />*/}
            {/*</NavbarDropdown>*/}

            {/*<NavbarDropdown title="documentation">*/}
            {/*    {flatRoutes(documentationRoutes.children).map(route => (*/}
            {/*        <Dropdown.Item*/}
            {/*            key={route.name}*/}
            {/*            as={Link}*/}
            {/*            className={route.active ? "link-600" : "text-500"}*/}
            {/*            to={route.to}*/}
            {/*            onClick={handleDropdownItemClick}*/}
            {/*        >*/}
            {/*            {route.name}*/}
            {/*        </Dropdown.Item>*/}
            {/*    ))}*/}
            {/*</NavbarDropdown>*/}
        </>
    );
};

export default NavbarTopDropDownMenus;
