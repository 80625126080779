import {useCallback, useMemo, useState} from 'react';

import useProtectedHttp from "@/hooks/useProtectedHttp";
import useProcessing from "@/hooks/useProcessing";
import PropTypes from "prop-types";
import {isNotEmptyArray} from "@/utils/common";

const useTag = () => {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post } = useProtectedHttp();

    const [tag, setTag] = useState(undefined);
    const [tagsPagination, setTagsPagination] = useState({});


    //create
    const createTagProcessing = useMemo(() => {
        return !!processing?.createTag;
    }, [processing?.createTag]);

    const createTagErrorMessage = useMemo(() => {
        return errorMessage?.createTag;
    }, [errorMessage?.createTag]);

    const createTag = useCallback((data, inSilentMode = false) => {
        return process('createTag', async () => {
            try {
                if(!inSilentMode) setTag(undefined);
                const result = await post(`/v1/tags/create`, data);
                setTag(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);


    //search
    const searchTagProcessing = useMemo(() => {
        return !!processing?.searchTag;
    }, [processing?.searchTag]);

    const searchTagErrorMessage = useMemo(() => {
        return errorMessage?.searchTag;
    }, [errorMessage?.searchTag]);

    const searchTag = useCallback( (data = {}, options = {page: 0, size: 10, sort: ''}, inSilentMode = false) => {
        return process('searchTag', async () => {
            try {
                const {page, size, sort} = options || {};
                if(!inSilentMode) setTagsPagination({});
                const result = size > 0 ? await post(`/v1/tags/search?page=${page}&size=${size}&sort=${sort}`, data)
                    : await get(`/v1/tags`);
                setTagsPagination(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post, get]);


    //get
    const getTagProcessing = useMemo(() => {
        return !!processing?.getTag;
    }, [processing?.getTag]);

    const getTagErrorMessage = useMemo(() => {
        return errorMessage?.getTag;
    }, [errorMessage?.getTag]);

    const getTag = useCallback((id, options = {includes: []}, inSilentMode = false) => {
        return process('getTag', async () => {
            try {
                const {includes} = options || {};
                if(!inSilentMode) setTag(undefined);
                const result = await get(`/v1/tags/${id}?includes=${(includes || []).join(',')}`);
                console.log('getTag', result);
                setTag(result);

                return Promise.resolve(result?.tag);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, get]);

    //delete
    const deleteTagProcessing = useMemo(() => {
        return !!processing?.deleteTag;
    }, [processing?.deleteTag]);

    const deleteTagErrorMessage = useMemo(() => {
        return errorMessage?.deleteTag;
    }, [errorMessage?.deleteTag]);

    const deleteTag = useCallback((data, inSilentMode = false) => {
        return process('deleteTag', async () => {
            try {
                if(!inSilentMode) setTag(undefined);
                const result = await post(`/v1/tags/delete`, data);
                setTag(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);


    //update
    const updateTagProcessing = useMemo(() => {
        return !!processing?.updateTag;
    }, [processing?.updateTag]);

    const updateTagErrorMessage = useMemo(() => {
        return errorMessage?.updateTag;
    }, [errorMessage?.updateTag]);

    const updateTag = useCallback((data, options = {updateFields: []}, inSilentMode = false) => {
        return process('updateTag', async () => {
            try {
                const {id} = data || {};
                const {updateFields} = options || {};
                const result = isNotEmptyArray(updateFields) ? await post(`/v1/tags/${id}/patch`, {data, updateFields}) : await post(`/v1/tags/${id}/update`, data);
                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);

    return {
        createTagProcessing, createTagErrorMessage, createTag,
        searchTagProcessing, searchTagErrorMessage, searchTag, tagsPagination,
        getTagProcessing, getTagErrorMessage, getTag, tag,
        deleteTagProcessing, deleteTagErrorMessage, deleteTag,
        updateTagProcessing, updateTagErrorMessage, updateTag,
    };
};

useTag.propTypes = {
    processing: PropTypes.shape({
        createTag: PropTypes.bool,
        searchTag: PropTypes.bool,
        getTag: PropTypes.bool,
        deleteTag: PropTypes.bool,
        updateTag: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        createTag: PropTypes.string,
        searchTag: PropTypes.string,
        getTag: PropTypes.string,
        deleteTag: PropTypes.string,
        updateTag: PropTypes.bool,
    }),
};

export default useTag;
