import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
} from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import { quizTypes } from "@/components/app/quizzes/utils";
import FormErrorMessage from "@/components/shared/FormErrorMessage";
import FormNumberInput from "@/components/shared/FormNumberInput";
import FormSelectInput from "@/components/shared/FormSelectInput";
import FormTextInput from "@/components/shared/FormTextInput";
import useQuiz from "@/hooks/biz/useQuiz";
import usePostCallback from "@/hooks/usePostCallback";

const DeleteQuizForm = forwardRef((props, ref) => {
    const { data } = props || {};

    const validationSchema = yup.object().shape({
        id: yup.number().moreThan(0, "Bài kiểm tra không hợp lệ"),
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...data,
        },
    });

    const {
        setValue,
        register,
        formState: { errors },
        trigger,
        getValues,
    } = methods;

    const handleValueChange = useCallback(
        accessor => value => {
            setValue(accessor, value, { shouldValidate: true });
            // await trigger();
        },
        [setValue],
    );

    const { deleteQuizProcessing, deleteQuizErrorMessage, deleteQuiz } =
        useQuiz();

    const disabled = useMemo(() => {
        return true;
    }, []);

    const errorMessage = useMemo(() => {
        return deleteQuizErrorMessage;
    }, [deleteQuizErrorMessage]);

    const onSubmit = useCallback(() => {
        const hasError = Object.keys(errors).length > 0;
        if (!hasError) {
            const values = getValues() || {};
            return deleteQuiz([values["id"]]);
        } else {
            return { error: errors };
        }
    }, [deleteQuiz, errors, getValues]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
        }),
        [onSubmit],
    );

    usePostCallback(trigger);

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Row className="g-3">
                    <Col lg={12}>
                        <FormErrorMessage errorMessage={errorMessage} />
                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"name"}
                            title={"Tên Bài kiểm tra"}
                            value={getValues("name")}
                            callback={handleValueChange("name")}
                            placeholder={"Nhập Tên Bài kiểm tra"}
                            required={true}
                            disabled={disabled}
                        />
                        <FormSelectInput
                            register={register}
                            errors={errors}
                            accessor={"quizType"}
                            title={"Loại Bài kiểm tra"}
                            value={getValues("quizType")}
                            nonValue={0}
                            defaultValue={1}
                            refData={quizTypes}
                            refAccessor={"name"}
                            callback={handleValueChange("quizType")}
                            placeholder={"Loại Bài kiểm tra"}
                            required={true}
                            disabled={disabled}
                        />
                        <FormNumberInput
                            register={register}
                            errors={errors}
                            accessor={"questionNumber"}
                            title={"Số lượng câu hỏi"}
                            value={getValues("questionNumber")}
                            callback={handleValueChange("questionNumber")}
                            placeholder={"Nhập Số lượng câu hỏi"}
                            required={true}
                            disabled={disabled}
                        />
                        <FormNumberInput
                            register={register}
                            errors={errors}
                            accessor={"passPoint"}
                            title={"Điểm đạt"}
                            value={getValues("passPoint")}
                            callback={handleValueChange("passPoint")}
                            placeholder={"Nhập Điểm đạt"}
                            required={true}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
});

DeleteQuizForm.displayName = "DeleteQuizForm";

export default DeleteQuizForm;
