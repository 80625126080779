import React from "react";

import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import Background from "@/components/common/Background";
import Flex from "@/components/common/Flex";
import Logo from "@/components/common/Logo";
import Section from "@/components/common/Section";

const AuthDefaultLayout = ({ leftSideContent, children, footer = true }) => {
    return (
        <Section fluid className="py-0">
            <Row className="g-0 min-vh-100 flex-center">
                <Col lg={8} xxl={5} className="py-3 position-relative">
                    <Card className="overflow-hidden z-1">
                        <Card.Body className="p-0">
                            <Row className="h-100 g-0">
                                <Col
                                    md={5}
                                    className="text-white text-center bg-card-gradient"
                                >
                                    <div className="position-relative p-4 pt-md-5 pb-md-7">
                                        <div
                                            className="z-1 position-relative"
                                            data-bs-theme="light"
                                        >
                                            <Logo
                                                width={45}
                                                textClass="text-white"
                                            />
                                            <p className="opacity-75 text-white">
                                                Chào mừng bạn đến với WebCMS!
                                            </p>
                                            <p className="opacity-75 text-white">
                                                Công cụ biên tập nội dung dựa
                                                trên đám mây do Unikom JSC cung
                                                cấp. Vui lòng Đăng nhập hoặc
                                                Đăng ký để bắt đầu.
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className="mt-0 mb-4 mt-md-4 mb-md-5"
                                        data-bs-theme="light"
                                    >
                                        {leftSideContent}

                                        {footer && (
                                            <p className="mb-0 mt-4 mt-md-5 fs-10 fw-semibold text-white opacity-75">
                                                Vui lòng đọc các{" "}
                                                <Link
                                                    className="text-decoration-underline text-white"
                                                    to="#!"
                                                >
                                                    điều khoản
                                                </Link>{" "}
                                                và{" "}
                                                <Link
                                                    className="text-decoration-underline text-white"
                                                    to="#!"
                                                >
                                                    điều kiện{" "}
                                                </Link>
                                                của chúng tôi
                                            </p>
                                        )}
                                    </div>
                                </Col>
                                <Col
                                    md={7}
                                    as={Flex}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <div className="p-4 p-md-5 flex-grow-1">
                                        {children}
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Section>
    );
};
AuthDefaultLayout.propTypes = {
    leftSideContent: PropTypes.node,
    children: PropTypes.node.isRequired,
    footer: PropTypes.bool,
};

export default AuthDefaultLayout;
