import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    Col,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import Flex from "@/components/common/Flex";
import { execute } from "@/utils/common";
import FormSelectInput from "@/components/shared/FormSelectInput";
import { questionTypes } from "@/components/app/questions/QuestionManagement";
import FormNumberInput from "@/components/shared/FormNumberInput";
import FormAnswerInput from "@/components/shared/FormAnswerInput";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { fromAnswers } from "@/components/app/questions/utils";

const QuestionContentHeader = ({ title, callback, setHideSidebar }) => {
    return (
        <div className="chat-content-header">
            <Row className="flex-between-center">
                <Col xs={6} md={8} as={Flex} alignItems="center">
                    <div
                        className="pe-3 text-700 d-md-none contacts-list-show cursor-pointer"
                        onClick={() => setHideSidebar(true)}
                    >
                        <FontAwesomeIcon icon="chevron-left" />
                    </div>
                    <div className="min-w-0">
                        <h5 className="mb-0 text-truncate fs-9 py-2">
                            {title}
                        </h5>
                    </div>
                </Col>
                <Col xs="auto">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip style={{ position: "fixed" }}>Sửa</Tooltip>
                        }
                    >
                        <Button
                            variant="primary"
                            size="sm"
                            className={"mx-1"}
                            onClick={e => {
                                e.preventDefault();
                                (async () =>
                                    execute(callback, { action: "edit" }))();
                            }}
                        >
                            <FontAwesomeIcon icon="edit" />
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip style={{ position: "fixed" }}>Xoá</Tooltip>
                        }
                    >
                        <Button
                            variant="danger"
                            size="sm"
                            onClick={e => {
                                e.preventDefault();
                                (async () =>
                                    execute(callback, { action: "delete" }))();
                            }}
                        >
                            <FontAwesomeIcon icon="trash" />
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>
        </div>
    );
};

QuestionContentHeader.propTypes = {
    title: PropTypes.string,
    callback: PropTypes.func,
    setHideSidebar: PropTypes.func,
};

const QuestionContentBody = ({ data }) => {
    const validationSchema = yup.object().shape({
        id: yup.number().moreThan(0, "Câu hỏi không hợp lệ"),
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...data,
            ...fromAnswers(data?.answers),
        },
    });

    const {
        reset,
        setValue,
        register,
        formState: { errors },
        trigger,
        getValues,
    } = methods;

    const handleValueChange = useCallback(
        accessor => value => {
            setValue(accessor, value, { shouldValidate: true });
            (async () => trigger())();
        },
        [setValue],
    );

    const disabled = useMemo(() => {
        return true;
    }, []);

    useEffect(() => {
        reset({
            ...data,
            ...fromAnswers(data?.answers),
        });
    }, [reset, data]);

    return (
        <div className={"p-3"}>
            <FormProvider {...methods}>
                <Form>
                    <Row className="g-3">
                        <Col lg={12}>
                            <Row className="gx-2 gy-3">
                                <Col md="6">
                                    <FormSelectInput
                                        register={register}
                                        errors={errors}
                                        accessor={"questionType"}
                                        title={"Loại câu hỏi"}
                                        value={getValues("questionType")}
                                        nonValue={0}
                                        defaultValue={1}
                                        refData={questionTypes}
                                        refAccessor={"name"}
                                        callback={handleValueChange(
                                            "questionType",
                                        )}
                                        placeholder={"Loại câu hỏi"}
                                        required={true}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col md="6">
                                    <FormNumberInput
                                        register={register}
                                        errors={errors}
                                        accessor={"point"}
                                        title={"Điểm"}
                                        value={getValues("point")}
                                        defaultValue={1}
                                        callback={handleValueChange("point")}
                                        placeholder={"Nhập Điểm"}
                                        required={true}
                                        disabled={disabled}
                                    />
                                </Col>
                            </Row>

                            <Row className="gx-2 gy-3">
                                <Col md="6">
                                    <FormAnswerInput
                                        register={register}
                                        errors={errors}
                                        accessor={"answer1Content"}
                                        title={"Đáp án 1"}
                                        value={getValues("answer1Content")}
                                        callback={handleValueChange(
                                            "answer1Content",
                                        )}
                                        extAccessor={"answer1Correct"}
                                        extTitle={"Sai/Đúng"}
                                        extValue={getValues("answer1Correct")}
                                        extCallback={handleValueChange(
                                            "answer1Correct",
                                        )}
                                        placeholder={"Nhập Đáp án 1"}
                                        required={true}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col md="6">
                                    <FormAnswerInput
                                        register={register}
                                        errors={errors}
                                        accessor={"answer2Content"}
                                        title={"Đáp án 2"}
                                        value={getValues("answer2Content")}
                                        callback={handleValueChange(
                                            "answer2Content",
                                        )}
                                        extAccessor={"answer2Correct"}
                                        extTitle={"Sai/Đúng"}
                                        extValue={getValues("answer2Correct")}
                                        extCallback={handleValueChange(
                                            "answer2Correct",
                                        )}
                                        placeholder={"Nhập Đáp án 2"}
                                        required={true}
                                        disabled={disabled}
                                    />
                                </Col>
                            </Row>
                            <Row className="gx-2 gy-3">
                                <Col md="6">
                                    <FormAnswerInput
                                        register={register}
                                        errors={errors}
                                        accessor={"answer3Content"}
                                        title={"Đáp án 3"}
                                        value={getValues("answer3Content")}
                                        callback={handleValueChange(
                                            "answer3Content",
                                        )}
                                        extAccessor={"answer3Correct"}
                                        extTitle={"Sai/Đúng"}
                                        extValue={getValues("answer3Correct")}
                                        extCallback={handleValueChange(
                                            "answer3Correct",
                                        )}
                                        placeholder={"Nhập Đáp án 3"}
                                        required={true}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col md="6">
                                    <FormAnswerInput
                                        register={register}
                                        errors={errors}
                                        accessor={"answer4Content"}
                                        title={"Đáp án 4"}
                                        value={getValues("answer4Content")}
                                        callback={handleValueChange(
                                            "answer4Content",
                                        )}
                                        extAccessor={"answer4Correct"}
                                        extTitle={"Sai/Đúng"}
                                        extValue={getValues("answer4Correct")}
                                        extCallback={handleValueChange(
                                            "answer4Correct",
                                        )}
                                        placeholder={"Nhập Đáp án 4"}
                                        required={true}
                                        disabled={disabled}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </FormProvider>
        </div>
    );
};

QuestionContentBody.propTypes = {
    data: PropTypes.object,
};

export { QuestionContentHeader, QuestionContentBody };
