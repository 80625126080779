import { useCallback, useMemo, useState } from "react";

import PropTypes from "prop-types";

import useProcessing from "@/hooks/useProcessing";
import useProtectedHttp from "@/hooks/useProtectedHttp";
import { isNotEmptyArray } from "@/utils/common";

const useCourse = () => {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post } = useProtectedHttp();

    const [course, setCourse] = useState(undefined);
    const [coursesPagination, setCoursesPagination] = useState({});

    //create
    const createCourseProcessing = useMemo(() => {
        return !!processing?.createCourse;
    }, [processing?.createCourse]);

    const createCourseErrorMessage = useMemo(() => {
        return errorMessage?.createCourse;
    }, [errorMessage?.createCourse]);

    const createCourse = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "createCourse",
                async () => {
                    try {
                        if (!inSilentMode) setCourse(undefined);
                        const result = await post(`/v1/courses/create`, data);
                        setCourse(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    //search
    const searchCourseProcessing = useMemo(() => {
        return !!processing?.searchCourse;
    }, [processing?.searchCourse]);

    const searchCourseErrorMessage = useMemo(() => {
        return errorMessage?.searchCourse;
    }, [errorMessage?.searchCourse]);

    const searchCourse = useCallback(
        (
            data = {},
            options = { page: 0, size: 10, sort: "" },
            inSilentMode = false,
        ) => {
            return process(
                "searchCourse",
                async () => {
                    try {
                        const { page, size, sort } = options || {};
                        if (!inSilentMode) setCoursesPagination({});
                        const result =
                            size > 0
                                ? await post(
                                      `/v1/courses/search?page=${page}&size=${size}&sort=${sort}`,
                                      data,
                                  )
                                : await get(`/v1/courses`);
                        setCoursesPagination(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post, get],
    );

    //get
    const getCourseProcessing = useMemo(() => {
        return !!processing?.getCourse;
    }, [processing?.getCourse]);

    const getCourseErrorMessage = useMemo(() => {
        return errorMessage?.getCourse;
    }, [errorMessage?.getCourse]);

    const getCourse = useCallback(
        (id, options = { includes: [] }, inSilentMode = false) => {
            return process(
                "getCourse",
                async () => {
                    try {
                        const { includes } = options || {};
                        if (!inSilentMode) setCourse(undefined);
                        const result = await get(
                            `/v1/courses/${id}?includes=${(includes || []).join(",")}`,
                        );
                        console.log("getCourse", result);
                        setCourse(result);

                        return Promise.resolve(result?.course);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, get],
    );

    //delete
    const deleteCourseProcessing = useMemo(() => {
        return !!processing?.deleteCourse;
    }, [processing?.deleteCourse]);

    const deleteCourseErrorMessage = useMemo(() => {
        return errorMessage?.deleteCourse;
    }, [errorMessage?.deleteCourse]);

    const deleteCourse = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "deleteCourse",
                async () => {
                    try {
                        if (!inSilentMode) setCourse(undefined);
                        const result = await post(`/v1/courses/delete`, data);
                        setCourse(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    //update
    const updateCourseProcessing = useMemo(() => {
        return !!processing?.updateCourse;
    }, [processing?.updateCourse]);

    const updateCourseErrorMessage = useMemo(() => {
        return errorMessage?.updateCourse;
    }, [errorMessage?.updateCourse]);

    const updateCourse = useCallback(
        (data, options = { updateFields: [] }, inSilentMode = false) => {
            return process(
                "updateCourse",
                async () => {
                    try {
                        const { id } = data || {};
                        const { updateFields } = options || {};
                        const result = isNotEmptyArray(updateFields)
                            ? await post(`/v1/courses/${id}/patch`, {
                                  data,
                                  updateFields,
                              })
                            : await post(`/v1/courses/${id}/update`, data);
                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    return {
        createCourseProcessing,
        createCourseErrorMessage,
        createCourse,
        searchCourseProcessing,
        searchCourseErrorMessage,
        searchCourse,
        coursesPagination,
        getCourseProcessing,
        getCourseErrorMessage,
        getCourse,
        course,
        deleteCourseProcessing,
        deleteCourseErrorMessage,
        deleteCourse,
        updateCourseProcessing,
        updateCourseErrorMessage,
        updateCourse,
    };
};

useCourse.propTypes = {
    processing: PropTypes.shape({
        createCourse: PropTypes.bool,
        searchCourse: PropTypes.bool,
        getCourse: PropTypes.bool,
        deleteCourse: PropTypes.bool,
        updateCourse: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        createCourse: PropTypes.string,
        searchCourse: PropTypes.string,
        getCourse: PropTypes.string,
        deleteCourse: PropTypes.string,
        updateCourse: PropTypes.bool,
    }),
};

export default useCourse;
