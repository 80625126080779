import {useCallback, useMemo, useState} from 'react';

import useProtectedHttp from "@/hooks/useProtectedHttp";
import useProcessing from "@/hooks/useProcessing";
import PropTypes from "prop-types";
import {isNotEmptyArray} from "@/utils/common";

const useProvince = () => {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post } = useProtectedHttp();

    const [province, setProvince] = useState(undefined);
    const [provincesPagination, setProvincesPagination] = useState({});


    //create
    const createProvinceProcessing = useMemo(() => {
        return !!processing?.createProvince;
    }, [processing?.createProvince]);

    const createProvinceErrorMessage = useMemo(() => {
        return errorMessage?.createProvince;
    }, [errorMessage?.createProvince]);

    const createProvince = useCallback((data, inSilentMode = false) => {
        return process('createProvince', async () => {
            try {
                if(!inSilentMode) setProvince(undefined);
                const result = await post(`/v1/provinces/create`, data);
                setProvince(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);


    //search
    const searchProvinceProcessing = useMemo(() => {
        return !!processing?.searchProvince;
    }, [processing?.searchProvince]);

    const searchProvinceErrorMessage = useMemo(() => {
        return errorMessage?.searchProvince;
    }, [errorMessage?.searchProvince]);

    const searchProvince = useCallback( (data = {}, options = {page: 0, size: 10, sort: ''}, inSilentMode = false) => {
        return process('searchProvince', async () => {
            try {
                const {page, size, sort} = options || {};
                if(!inSilentMode) setProvincesPagination({});
                const result = size > 0 ? await post(`/v1/provinces/search?page=${page}&size=${size}&sort=${sort}`, data) : await get(`/v1/provinces`);
                setProvincesPagination(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post, get]);


    //get
    const getProvinceProcessing = useMemo(() => {
        return !!processing?.getProvince;
    }, [processing?.getProvince]);

    const getProvinceErrorMessage = useMemo(() => {
        return errorMessage?.getProvince;
    }, [errorMessage?.getProvince]);

    const getProvince = useCallback((id, options = {includes: []}, inSilentMode = false) => {
        return process('getProvince', async () => {
            try {
                const {includes} = options || {};
                if(!inSilentMode) setProvince(undefined);
                const result = await get(`/v1/provinces/${id}?includes=${(includes || []).join(',')}`);
                console.log('getProvince', result);
                setProvince(result);

                return Promise.resolve(result?.province);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, get]);


    //delete
    const deleteProvinceProcessing = useMemo(() => {
        return !!processing?.deleteProvince;
    }, [processing?.deleteProvince]);

    const deleteProvinceErrorMessage = useMemo(() => {
        return errorMessage?.deleteProvince;
    }, [errorMessage?.deleteProvince]);

    const deleteProvince = useCallback((data, inSilentMode = false) => {
        return process('deleteProvince', async () => {
            try {
                if(!inSilentMode) setProvince(undefined);
                const result = await post(`/v1/provinces/delete`, data);
                setProvince(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);


    //update
    const updateProvinceProcessing = useMemo(() => {
        return !!processing?.updateProvince;
    }, [processing?.updateProvince]);

    const updateProvinceErrorMessage = useMemo(() => {
        return errorMessage?.updateProvince;
    }, [errorMessage?.updateProvince]);

    const updateProvince = useCallback((data, options = {updateFields: []}, inSilentMode = false) => {
        return process('updateProvince', async () => {
            try {
                const {id} = data;
                const {updateFields} = options || {};
                const result = isNotEmptyArray(updateFields) ? await post(`/v1/provinces/${id}/patch`, {data, updateFields}) : await post(`/v1/provinces/${id}/update`, data);
                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);

    return {
        createProvinceProcessing, createProvinceErrorMessage, createProvince, province,
        searchProvinceProcessing, searchProvinceErrorMessage, searchProvince, provincesPagination,
        getProvinceProcessing, getProvinceErrorMessage, getProvince,
        deleteProvinceProcessing, deleteProvinceErrorMessage, deleteProvince,
        updateProvinceProcessing, updateProvinceErrorMessage, updateProvince,
    };
};

useProvince.propTypes = {
    processing: PropTypes.shape({
        createProvince: PropTypes.bool,
        searchProvince: PropTypes.bool,
        getProvince: PropTypes.bool,
        deleteProvince: PropTypes.bool,
        updateProvince: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        createProvince: PropTypes.string,
        searchProvince: PropTypes.string,
        getProvince: PropTypes.string,
        deleteProvince: PropTypes.string,
        updateProvince: PropTypes.bool,
    }),
};

export default useProvince;
