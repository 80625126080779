import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useUser from "@/hooks/biz/useUser";
import usePostCallback from "@/hooks/usePostCallback";
import FormDateInput from "@/components/shared/FormDateInput";
import FormTextInput from "@/components/shared/FormTextInput";
import FormErrorMessage from "@/components/shared/FormErrorMessage";
import { isEmptyOrWhitespace, toDate } from "@/utils/common";
import FormSelectInput from "@/components/shared/FormSelectInput";
import useProvince from "@/hooks/biz/useProvince";
import { vietnamesePhoneNumberRegex } from "@/utils/regex";

const CreateOrEditUserForm = forwardRef((props, ref) => {
    const { data } = props || {};
    const validationSchema = yup.object().shape({
        fullName: yup.string().required("Họ và tên không được để trống."),
        email: yup
            .string()
            .email("Định dạng Email không hợp lệ.")
            .required("Email không được để trống."),
        phoneNumber: yup
            .string()
            .transform(value =>
                isEmptyOrWhitespace(value) ? undefined : value,
            )
            .nullable()
            .matches(
                vietnamesePhoneNumberRegex,
                "Định dạng Số điện thoại không hợp lệ.",
            ),
        provinceId: yup
            .number()
            .required("Tỉnh/Thành phố không được để trống."),
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...data,
        },
    });

    const {
        setValue,
        register,
        formState: { errors },
        trigger,
        getValues,
    } = methods;

    const handleValueChange = useCallback(
        accessor => value => {
            setValue(accessor, value, { shouldValidate: true });
            // await trigger();
        },
        [setValue],
    );

    const {
        createUserProcessing,
        createUserErrorMessage,
        createUser,
        updateUserProcessing,
        updateUserErrorMessage,
        updateUser,
    } = useUser();

    const disabled = useMemo(() => {
        return createUserProcessing || updateUserProcessing;
    }, [createUserProcessing, updateUserProcessing]);

    const errorMessage = useMemo(() => {
        return createUserErrorMessage || updateUserErrorMessage;
    }, [createUserErrorMessage, updateUserErrorMessage]);

    const onSubmit = useCallback(() => {
        const hasError = Object.keys(errors).length > 0;
        if (!hasError) {
            const values = getValues();
            return values?.id && values?.id > 0
                ? updateUser(values)
                : createUser(values);
        } else {
            return { error: errors };
        }
    }, [createUser, errors, getValues, updateUser]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
        }),
        [onSubmit],
    );

    const {
        searchProvince,
        provincesPagination: { content: provinces },
    } = useProvince();

    usePostCallback(trigger, () =>
        searchProvince({}, { page: 0, size: 0, sort: "" }),
    );

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Row className="g-3">
                    <Col lg={12}>
                        <FormErrorMessage errorMessage={errorMessage} />
                        <Row className="gx-2 gy-3">
                            <Col md="6">
                                <FormTextInput
                                    register={register}
                                    errors={errors}
                                    accessor={"fullName"}
                                    title={"Họ và tên"}
                                    value={getValues("fullName")}
                                    callback={handleValueChange("fullName")}
                                    placeholder={"Nhập Họ và Tên"}
                                    required={true}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col md="6">
                                <FormTextInput
                                    register={register}
                                    errors={errors}
                                    accessor={"email"}
                                    title={"Email"}
                                    value={getValues("email")}
                                    callback={handleValueChange("email")}
                                    placeholder={"Nhập Địa chỉ email"}
                                    required={true}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                        <Row className="gx-2 gy-3">
                            <Col md="6">
                                <FormTextInput
                                    register={register}
                                    errors={errors}
                                    accessor={"phoneNumber"}
                                    title={"Số điện thoại"}
                                    value={getValues("phoneNumber")}
                                    callback={handleValueChange("phoneNumber")}
                                    placeholder={"Nhập Số điện thoại"}
                                    disabled={disabled}
                                />
                            </Col>
                            <Col md="6">
                                <FormDateInput
                                    register={register}
                                    errors={errors}
                                    accessor={"dateOfBirth"}
                                    title={"Ngày sinh"}
                                    value={toDate(getValues("dateOfBirth"))}
                                    callback={handleValueChange("dateOfBirth")}
                                    placeholder={"Chọn Ngày sinh"}
                                    disabled={disabled}
                                />
                            </Col>
                        </Row>
                        <FormSelectInput
                            register={register}
                            errors={errors}
                            accessor={"provinceId"}
                            title={"Tỉnh/Thành phố"}
                            value={getValues("provinceId")}
                            nonValue={0}
                            defaultValue={0}
                            refData={provinces}
                            refAccessor={"provinceName"}
                            callback={handleValueChange("provinceId")}
                            placeholder={"Tỉnh/Thành phố"}
                            required={true}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
});

export default CreateOrEditUserForm;
