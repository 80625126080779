import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
} from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useLecture from "@/hooks/biz/useLecture";
import usePostCallback from "@/hooks/usePostCallback";
import FormErrorMessage from "@/components/shared/FormErrorMessage";
import FormTextInput from "@/components/shared/FormTextInput";
import FormSelectInput from "@/components/shared/FormSelectInput";
import useCourse from "@/hooks/biz/useCourse";
import FormNumberInput from "@/components/shared/FormNumberInput";

const DeleteLectureForm = forwardRef((props, ref) => {
    const { data } = props || {};

    const validationSchema = yup.object().shape({
        id: yup.number().moreThan(0, "Bài giảng không hợp lệ"),
    });

    const methods = useForm({
        mode: "all",
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...data,
        },
    });

    const {
        setValue,
        register,
        formState: { errors },
        trigger,
        getValues,
    } = methods;

    const handleValueChange = useCallback(
        accessor => value => {
            setValue(accessor, value, { shouldValidate: true });
            // await trigger();
        },
        [setValue],
    );

    const {
        deleteLectureProcessing,
        deleteLectureErrorMessage,
        deleteLecture,
    } = useLecture();

    const disabled = useMemo(() => {
        return true;
    }, []);

    const errorMessage = useMemo(() => {
        return deleteLectureErrorMessage;
    }, [deleteLectureErrorMessage]);

    const onSubmit = useCallback(() => {
        const hasError = Object.keys(errors).length > 0;
        if (!hasError) {
            const values = getValues() || {};
            return deleteLecture([values["id"]]);
        } else {
            return { error: errors };
        }
    }, [deleteLecture, errors, getValues]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
        }),
        [onSubmit],
    );

    const {
        searchCourse,
        coursesPagination: { content: courses },
    } = useCourse();

    usePostCallback(trigger, () =>
        searchCourse({}, { page: 0, size: 0, sort: "" }),
    );

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Row className="g-3">
                    <Col lg={12}>
                        <FormErrorMessage errorMessage={errorMessage} />
                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"title"}
                            title={"Tên Bài giảng"}
                            value={getValues("title")}
                            callback={handleValueChange("title")}
                            placeholder={"Nhập Tên Bài giảng"}
                            required={true}
                            disabled={disabled}
                        />
                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"topic"}
                            title={"Chủ đề"}
                            value={getValues("topic")}
                            callback={handleValueChange("topic")}
                            placeholder={"Nhập Chủ đề"}
                            required={true}
                            disabled={disabled}
                        />
                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"videoUrl"}
                            title={"Video (Youtube)"}
                            value={getValues("videoUrl")}
                            callback={handleValueChange("videoUrl")}
                            placeholder={
                                "Nhập hoặc Copy/Paste đường dẫn Video Youtube"
                            }
                            required={true}
                            disabled={disabled}
                        />
                        <FormTextInput
                            register={register}
                            errors={errors}
                            accessor={"description"}
                            title={"Nội dung"}
                            value={getValues("description")}
                            callback={handleValueChange("description")}
                            placeholder={"Nhập Nội dung"}
                            required={false}
                            disabled={disabled}
                        />
                        <FormSelectInput
                            register={register}
                            errors={errors}
                            accessor={"courseId"}
                            title={"Khoá học"}
                            value={getValues("courseId")}
                            nonValue={0}
                            defaultValue={0}
                            refData={courses}
                            refAccessor={"courseName"}
                            callback={handleValueChange("courseId")}
                            placeholder={"Khoá học"}
                            required={true}
                            disabled={disabled}
                        />
                        <FormNumberInput
                            register={register}
                            errors={errors}
                            accessor={"orderNum"}
                            title={"Số Thứ tự"}
                            value={getValues("orderNum")}
                            callback={handleValueChange("orderNum")}
                            placeholder={"Nhập Số Thứ tự"}
                            required={true}
                            disabled={disabled}
                        />
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
});

export default DeleteLectureForm;
