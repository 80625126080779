import React, {useRef} from 'react';
import {Button, Form} from 'react-bootstrap';
import RequiredSign from "@/components/extra/RequiredSign";
import {execute} from "@/utils/common";
import FormDialog from "@/components/shared/FormDialog";
import ReactPlayer from 'react-player/youtube'
import IconButton from "@/components/common/IconButton";

const FormYoutubeUrlInput = ({register, errors, accessor, title, value, callback, placeholder = '', required = false, disabled = false}) => {
    const formDialogRef = useRef(null);

    return (
        <Form.Group>
            <Form.Label>
                {title}: {required && <RequiredSign/>}
            </Form.Label>
            <div style={{ position: 'relative' }}> {/* Parent container */}
                <Form.Control
                    {...register(accessor)}
                    disabled={disabled}
                    type="text"
                    value={value || ''}
                    onChange={(e) => execute(callback, e.target.value)}
                    isInvalid={!!errors[accessor]}
                    placeholder={placeholder}
                />
                {value && <IconButton
                    variant="danger"
                    size="sm"
                    icon="eye"
                    transform="shrink-3"
                    style={{ position: 'absolute', top: '0.25rem', right: '0.25rem'}}
                    onClick={(e) => {
                        e.preventDefault();
                        (async () => execute(formDialogRef?.current?.showDialog))();
                    }}
                >
                    <span className="d-none d-sm-inline-block ms-1">Xem trước</span>
                </IconButton>}
            </div>

            <Form.Control.Feedback type="invalid"
                                   className={errors[accessor]?.message ? 'block' : 'hidden'}>
                {errors[accessor]?.message}
            </Form.Control.Feedback>
            {value && (
                <FormDialog ref={formDialogRef}
                            title={`Youtube`}
                            size={'lg'}
                >
                    <div
                        style={{ position: 'relative', paddingTop: '56.25%' }}
                    >
                        <ReactPlayer url={value} width="100%" height="100%"
                            style={{ position: 'absolute', top: 0, left: 0 }}
                        />
                    </div>
                </FormDialog>
            )}
        </Form.Group>
    );
};

export default FormYoutubeUrlInput;
