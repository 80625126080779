import {useCallback, useMemo} from 'react';
import axios from 'axios';
import useAuth from './useAuth';

const API_URL = process.env.REACT_APP_API_URL;  // Replace with your API URL

const toRuntimeError = (error) => {
  // console.log('error', error);
  return {
    url: error?.request?.responseURL,
    code: error?.code,
    message: error?.response?.data?.message || error?.response?.message || error?.message,
    status: error?.response?.status,
    statusText: error?.response?.statusText,
  };
}

const toRuntimeResponse = (response) => {
  return response?.data?.data || response?.data;
}

const useProtectedHttp = () => {

  const { auth, refreshToken, logout } = useAuth();

  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: API_URL,
      headers: {
        'Authorization': `Bearer ${auth?.jwt?.accessToken}`,
        'Accept-Language': 'vi-VN'
      },
    })

    const retry = async (config) => {
      try {
        await refreshToken();
        config.headers['Authorization'] = `Bearer ${auth?.jwt?.accessToken}`;
        config.headers['Accept-Language'] = 'vi-VN';
        return instance(config);
      } catch (error) {
        await logout();
        return Promise.reject(toRuntimeError(error));
      }
    }

    instance.interceptors.response.use(
        response => response,
        async error => {
          const config = error?.config;
          if (error?.response?.status === 401) {
            if(!config?._retry) return retry({...config, ...{_retry: true}});
            else {
              await logout();
            }
          }
          return Promise.reject(toRuntimeError(error));
        }
    );

    return instance;
  }, [auth?.jwt?.accessToken, logout, refreshToken]);



  const get = useCallback(async (url, config = {}) => {
    return toRuntimeResponse(await axiosInstance.get(url, config));
  }, [axiosInstance]);

  const post = useCallback( async (url, data, config = {}) => {
    return toRuntimeResponse(await axiosInstance.post(url, data, config));
  }, [axiosInstance]);

  const put = useCallback( async (url, data, config = {}) => {
    return toRuntimeResponse(await axiosInstance.put(url, data, config));
  }, [axiosInstance]);

  const del = useCallback( async (url, config = {}) => {
    return toRuntimeResponse(await axiosInstance.delete(url, config));
  }, [axiosInstance]);

  const upload = useCallback( async (url, formData, config = {}) => {
    return toRuntimeResponse(await axiosInstance.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      ...config,
    }));
  }, [axiosInstance]);

  const download = useCallback( async (url, config = {}) => {
    return toRuntimeResponse(await axiosInstance.get(url, {
      responseType: 'blob',
      ...config,
    }));
  }, [axiosInstance]);

  return {
    get,
    post,
    put,
    del,
    upload,
    download,
  };
};

export default useProtectedHttp;
