import { useCallback, useMemo, useState } from "react";

import PropTypes from "prop-types";

import useProcessing from "@/hooks/useProcessing";
import useProtectedHttp from "@/hooks/useProtectedHttp";
import { isNotEmptyArray } from "@/utils/common";

const useQuizQuestion = () => {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post } = useProtectedHttp();

    const [quizQuestion, setQuizQuestion] = useState(undefined);
    const [quizQuestionsPagination, setQuizQuestionsPagination] = useState({});
    const [questionsOfQuiz, setQuestionsOfQuiz] = useState([]);

    //create
    const createQuizQuestionProcessing = useMemo(() => {
        return !!processing?.createQuizQuestion;
    }, [processing?.createQuizQuestion]);

    const createQuizQuestionErrorMessage = useMemo(() => {
        return errorMessage?.createQuizQuestion;
    }, [errorMessage?.createQuizQuestion]);

    const createQuizQuestion = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "createQuizQuestion",
                async () => {
                    try {
                        if (!inSilentMode) setQuizQuestion(undefined);
                        const result = await post(
                            `/v1/quiz_questions/create`,
                            data,
                        );
                        setQuizQuestion(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    //search
    const searchQuizQuestionProcessing = useMemo(() => {
        return !!processing?.searchQuizQuestion;
    }, [processing?.searchQuizQuestion]);

    const searchQuizQuestionErrorMessage = useMemo(() => {
        return errorMessage?.searchQuizQuestion;
    }, [errorMessage?.searchQuizQuestion]);

    const searchQuizQuestion = useCallback(
        (
            data = {},
            options = { page: 0, size: 10, sort: "" },
            inSilentMode = false,
        ) => {
            return process(
                "searchQuizQuestion",
                async () => {
                    try {
                        const { page, size, sort } = options || {};
                        if (!inSilentMode) setQuizQuestionsPagination({});
                        const result =
                            size > 0
                                ? await post(
                                      `/v1/quiz_questions/search?page=${page}&size=${size}&sort=${sort}`,
                                      data,
                                  )
                                : await get(`/v1/quiz_questions`);
                        setQuizQuestionsPagination(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post, get],
    );

    //get
    const getQuizQuestionProcessing = useMemo(() => {
        return !!processing?.getQuizQuestion;
    }, [processing?.getQuizQuestion]);

    const getQuizQuestionErrorMessage = useMemo(() => {
        return errorMessage?.getQuizQuestion;
    }, [errorMessage?.getQuizQuestion]);

    const getQuizQuestion = useCallback(
        (id, options = { includes: [] }, inSilentMode = false) => {
            return process(
                "getQuizQuestion",
                async () => {
                    try {
                        const { includes } = options || {};
                        if (!inSilentMode) setQuizQuestion(undefined);
                        const result = await get(
                            `/v1/quiz_questions/${id}?includes=${(includes || []).join(",")}`,
                        );
                        setQuizQuestion(result);

                        return Promise.resolve(result?.quizQuestion);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, get],
    );

    //delete
    const deleteQuizQuestionProcessing = useMemo(() => {
        return !!processing?.deleteQuizQuestion;
    }, [processing?.deleteQuizQuestion]);

    const deleteQuizQuestionErrorMessage = useMemo(() => {
        return errorMessage?.deleteQuizQuestion;
    }, [errorMessage?.deleteQuizQuestion]);

    const deleteQuizQuestion = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "deleteQuizQuestion",
                async () => {
                    try {
                        if (!inSilentMode) setQuizQuestion(undefined);
                        const result = await post(
                            `/v1/quiz_questions/delete`,
                            data,
                        );
                        setQuizQuestion(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    //update
    const updateQuizQuestionProcessing = useMemo(() => {
        return !!processing?.updateQuizQuestion;
    }, [processing?.updateQuizQuestion]);

    const updateQuizQuestionErrorMessage = useMemo(() => {
        return errorMessage?.updateQuizQuestion;
    }, [errorMessage?.updateQuizQuestion]);

    const updateQuizQuestion = useCallback(
        (data, options = { updateFields: [] }, inSilentMode = false) => {
            return process(
                "updateQuizQuestion",
                async () => {
                    try {
                        const { id } = data || {};
                        const { updateFields } = options || {};
                        const result = isNotEmptyArray(updateFields)
                            ? await post(`/v1/quiz_questions/${id}/patch`, {
                                  data,
                                  updateFields,
                              })
                            : await post(
                                  `/v1/quiz_questions/${id}/update`,
                                  data,
                              );
                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post],
    );

    //get
    const getQuestionsOfQuizProcessing = useMemo(() => {
        return !!processing?.getQuestionsOfQuiz;
    }, [processing?.getQuestionsOfQuiz]);

    const getQuestionsOfQuizErrorMessage = useMemo(() => {
        return errorMessage?.getQuestionsOfQuiz;
    }, [errorMessage?.getQuestionsOfQuiz]);

    const getQuestionsOfQuiz = useCallback(
        (id, inSilentMode = false) => {
            return process(
                "getQuestionsOfQuiz",
                async () => {
                    try {
                        if (!inSilentMode) setQuestionsOfQuiz(undefined);
                        const result = await get(`/v1/quizzes/${id}/questions`);
                        setQuestionsOfQuiz(result);

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, get],
    );

    return {
        createQuizQuestionProcessing,
        createQuizQuestionErrorMessage,
        createQuizQuestion,
        searchQuizQuestionProcessing,
        searchQuizQuestionErrorMessage,
        searchQuizQuestion,
        quizQuestionsPagination,
        getQuizQuestionProcessing,
        getQuizQuestionErrorMessage,
        getQuizQuestion,
        quizQuestion,
        deleteQuizQuestionProcessing,
        deleteQuizQuestionErrorMessage,
        deleteQuizQuestion,
        updateQuizQuestionProcessing,
        updateQuizQuestionErrorMessage,
        updateQuizQuestion,
        getQuestionsOfQuizProcessing,
        getQuestionsOfQuizErrorMessage,
        getQuestionsOfQuiz,
        questionsOfQuiz,
    };
};

useQuizQuestion.propTypes = {
    processing: PropTypes.shape({
        createQuizQuestion: PropTypes.bool,
        searchQuizQuestion: PropTypes.bool,
        getQuizQuestion: PropTypes.bool,
        deleteQuizQuestion: PropTypes.bool,
        updateQuizQuestion: PropTypes.bool,
        getQuestionsOfQuiz: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        createQuizQuestion: PropTypes.string,
        searchQuizQuestion: PropTypes.string,
        getQuizQuestion: PropTypes.string,
        deleteQuizQuestion: PropTypes.string,
        updateQuizQuestion: PropTypes.bool,
        getQuestionsOfQuiz: PropTypes.bool,
    }),
};

export default useQuizQuestion;
