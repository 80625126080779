import { useCallback, useMemo, useState } from "react";

import PropTypes from "prop-types";

import useProcessing from "@/hooks/useProcessing";
import useProtectedHttp from "@/hooks/useProtectedHttp";

const useCourseQuiz = ({ courseId }) => {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post } = useProtectedHttp();

    const [quizzesOfCourse, setQuizzesOfCourse] = useState([]);
    //create
    const createCourseQuizProcessing = useMemo(() => {
        return !!processing?.createCourseQuiz;
    }, [processing?.createCourseQuiz]);

    const createCourseQuizErrorMessage = useMemo(() => {
        return errorMessage?.createCourseQuiz;
    }, [errorMessage?.createCourseQuiz]);

    const createCourseQuiz = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "createCourseQuiz",
                async () => {
                    try {
                        const result = await post(
                            `/v1/courses/${courseId}/quizzes/create`,
                            data,
                        );

                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post, courseId],
    );

    //delete
    const deleteCourseQuizProcessing = useMemo(() => {
        return !!processing?.deleteCourseQuiz;
    }, [processing?.deleteCourseQuiz]);

    const deleteCourseQuizErrorMessage = useMemo(() => {
        return errorMessage?.deleteCourseQuiz;
    }, [errorMessage?.deleteCourseQuiz]);

    const deleteCourseQuiz = useCallback(
        (data, inSilentMode = false) => {
            return process(
                "deleteCourseQuiz",
                async () => {
                    try {
                        const result = await post(
                            `/v1/courses/${courseId}/quizzes/delete`,
                            data,
                        );
                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, post, courseId],
    );

    const getQuizzesOfCourseProcessing = useMemo(() => {
        return !!processing?.getQuizzesOfCourse;
    }, [processing?.getQuizzesOfCourse]);

    const getQuizzesOfCourseErrorMessage = useMemo(() => {
        return errorMessage?.getQuizzesOfCourse;
    }, [errorMessage?.getQuizzesOfCourse]);

    const getQuizzesOfCourse = useCallback(
        ({ options = { includes: ["question"] }, inSilentMode = false }) => {
            const { includes } = options || {};

            return process(
                "getQuizzesOfCourse",
                async () => {
                    try {
                        if (!inSilentMode) setQuizzesOfCourse([]);
                        const result = await get(
                            `/v1/courses/${courseId}/quizzes?includes=${(includes || []).join(",")}`,
                        );

                        setQuizzesOfCourse(result);
                        return Promise.resolve(result);
                    } catch (error) {
                        return Promise.reject(error);
                    }
                },
                inSilentMode,
            );
        },
        [process, get, courseId],
    );

    return {
        createCourseQuizProcessing,
        createCourseQuizErrorMessage,
        createCourseQuiz,

        deleteCourseQuizProcessing,
        deleteCourseQuizErrorMessage,
        deleteCourseQuiz,

        getQuizzesOfCourseProcessing,
        getQuizzesOfCourseErrorMessage,
        getQuizzesOfCourse,
        quizzesOfCourse,
    };
};

useCourseQuiz.propTypes = {
    processing: PropTypes.shape({
        createCourseQuiz: PropTypes.bool,
        deleteCourseQuiz: PropTypes.bool,
        getQuizzesOfCourse: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        createCourseQuiz: PropTypes.string,
        deleteCourseQuiz: PropTypes.string,
        getQuizzesOfCourse: PropTypes.bool,
    }),
};

export default useCourseQuiz;
