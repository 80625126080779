import React, {
    forwardRef,
    useCallback,
    useImperativeHandle,
    useMemo,
} from "react";
import { Col, Row } from "react-bootstrap";
import useQuestion from "@/hooks/biz/useQuestion";
import FormErrorMessage from "@/components/shared/FormErrorMessage";

const BulkDeleteQuestionForm = forwardRef((props, ref) => {
    const selectedData = useMemo(() => {
        return props?.selectedData || [];
    }, [props?.selectedData]);

    const {
        deleteQuestionProcessing,
        deleteQuestionErrorMessage,
        deleteQuestion,
    } = useQuestion();

    const errorMessage = useMemo(() => {
        return deleteQuestionErrorMessage;
    }, [deleteQuestionErrorMessage]);

    const onSubmit = useCallback(() => {
        if (selectedData.length > 0) {
            return deleteQuestion(selectedData?.map(item => item.id) || []);
        }
    }, [selectedData, deleteQuestion]);

    useImperativeHandle(
        ref,
        () => ({
            submit: onSubmit,
        }),
        [onSubmit],
    );

    return (
        <Row className="g-3">
            <Col lg={12}>
                <FormErrorMessage errorMessage={errorMessage} />
                <span>{`Bạn có chắc chắn muốn xoá ${selectedData?.length || 0} Câu hỏi đã chọn không?`}</span>
            </Col>
        </Row>
    );
});

export default BulkDeleteQuestionForm;
