import {useState, useEffect} from 'react';

const useDebounce = (value, delay = 200) => {
    const [debouncedValue, setDebouncedValue] = useState(undefined);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue((prevValue) => {
                return prevValue !== value ? value : prevValue;
            });
        }, delay);

        // Cleanup function to clear the timeout if value changes or component unmounts
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

export default useDebounce;
