export const toAnswers = obj => {
    const answers = [];
    for (let index = 0; index < 4; index++) {
        answers.push({
            id: obj[`answer${index + 1}Id`],
            content: obj[`answer${index + 1}Content`],
            isCorrect: obj[`answer${index + 1}Correct`] ? 1 : 0,
        });
    }
    return answers;
};

export const fromAnswers = (answers = []) => {
    const obj = {};
    for (let index = 0; index < answers.length; index++) {
        const answer = answers[index];
        obj[`answer${index + 1}Id`] = answer?.id;
        obj[`answer${index + 1}Content`] = answer?.content;
        obj[`answer${index + 1}Correct`] = answer?.isCorrect;
    }
    return obj;
};
