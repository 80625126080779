import React from 'react';
import loadingSquare from "@/assets/images/animated-icons/loading-square.json";
import Lottie from "lottie-react";

const Loading = ({size = 32}) => {
  return (
      <Lottie animationData={loadingSquare} loop={true}  style={{height: `${size}px`, width: `${size}px`}} />
  );
};
export default Loading;
