import React from 'react';
import {Form} from 'react-bootstrap';
import RequiredSign from "@/components/extra/RequiredSign";
import {execute} from "@/utils/common";

const FormSelectInput = ({register, errors, accessor, title, value, nonValue, defaultValue, refData, refAccessor, callback, placeholder = '', required = false, disabled = false}) => {
    return (
        <Form.Group>
            <Form.Label>
                {title}: {required && <RequiredSign/>}
            </Form.Label>
            <Form.Select
                {...register(accessor)}
                disabled={disabled}
                value={value || defaultValue || nonValue}
                onChange={(e) => execute(callback, e.target.value)}
                isInvalid={!!errors[accessor]}
                placeholder={placeholder}
            >
                <option value={nonValue}>Lựa chọn</option>
                {
                    React.Children.toArray(refData?.map((item) => {
                        return <option value={item.id}>{`${item[refAccessor]}`}</option>;
                    }))
                }

            </Form.Select>
            <Form.Control.Feedback type="invalid"
                                   className={errors[accessor]?.message ? 'block' : 'hidden'}>
                {errors[accessor]?.message}
            </Form.Control.Feedback>
        </Form.Group>
    );
};

export default FormSelectInput;
