import {useCallback, useMemo, useState} from 'react';

import useProtectedHttp from "@/hooks/useProtectedHttp";
import useProcessing from "@/hooks/useProcessing";
import PropTypes from "prop-types";
import {isNotEmptyArray} from "@/utils/common";

const useUser = () => {
    const { processing, errorMessage, process } = useProcessing();

    const { get, post } = useProtectedHttp();

    const [user, setUser] = useState(undefined);
    const [profile, setProfile] = useState(undefined);
    const [usersPagination, setUsersPagination] = useState({});


    //create
    const createUserProcessing = useMemo(() => {
        return !!processing?.createUser;
    }, [processing?.createUser]);

    const createUserErrorMessage = useMemo(() => {
        return errorMessage?.createUser;
    }, [errorMessage?.createUser]);

    const createUser = useCallback((data, inSilentMode = false) => {
        return process('createUser', async () => {
            try {
                if(!inSilentMode) setUser(undefined);
                const result = await post(`/v1/sys_users/create`, data);
                setUser(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);


    //search
    const searchUserProcessing = useMemo(() => {
        return !!processing?.searchUser;
    }, [processing?.searchUser]);

    const searchUserErrorMessage = useMemo(() => {
        return errorMessage?.searchUser;
    }, [errorMessage?.searchUser]);

    const searchUser = useCallback( (data = {}, options = {page: 0, size: 10, sort: ''}, inSilentMode = false) => {
        return process('searchUser', async () => {
            try {
                const {page, size, sort} = options || {};
                if(!inSilentMode) setUsersPagination({});
                const result = size > 0 ? await post(`/v1/sys_users/search?page=${page}&size=${size}&sort=${sort}`, data)
                    : await get(`/v1/sys_users`);
                setUsersPagination(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post, get]);


    //get
    const getUserProcessing = useMemo(() => {
        return !!processing?.getUser;
    }, [processing?.getUser]);

    const getUserErrorMessage = useMemo(() => {
        return errorMessage?.getUser;
    }, [errorMessage?.getUser]);

    const getUser = useCallback((id, options = {includes: []}, inSilentMode = false) => {
        return process('getUser', async () => {
            try {
                const {includes} = options || {};
                if(!inSilentMode) setUser(undefined);
                const result = await get(`/v1/sys_users/${id}?includes=${(includes || []).join(',')}`);
                console.log('getUser', result);
                setUser(result);

                return Promise.resolve(result?.user);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, get]);


    const getProfileProcessing = useMemo(() => {
        return !!processing?.getProfile;
    }, [processing?.getProfile]);

    const getProfileErrorMessage = useMemo(() => {
        return errorMessage?.getProfile;
    }, [errorMessage?.getProfile]);

    const getProfile = useCallback((id, options = {includes: []}, inSilentMode = false) => {
        return process('getProfile', async () => {
            try {
                const {includes} = options || {};
                if(!inSilentMode) setProfile(undefined);
                const result = await get(`/v1/sys_users/me?includes=${(includes || []).join(',')}`);
                console.log('getProfile', result);
                setProfile(result);

                return Promise.resolve(result?.user);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, get]);

    //delete
    const deleteUserProcessing = useMemo(() => {
        return !!processing?.deleteUser;
    }, [processing?.deleteUser]);

    const deleteUserErrorMessage = useMemo(() => {
        return errorMessage?.deleteUser;
    }, [errorMessage?.deleteUser]);

    const deleteUser = useCallback((data, inSilentMode = false) => {
        return process('deleteUser', async () => {
            try {
                if(!inSilentMode) setUser(undefined);
                const result = await post(`/v1/sys_users/delete`, data);
                setUser(result);

                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);


    //update
    const updateUserProcessing = useMemo(() => {
        return !!processing?.updateUser;
    }, [processing?.updateUser]);

    const updateUserErrorMessage = useMemo(() => {
        return errorMessage?.updateUser;
    }, [errorMessage?.updateUser]);

    const updateUser = useCallback((data, options = {updateFields: []}, inSilentMode = false) => {
        return process('updateUser', async () => {
            try {
                const {id} = data || {};
                const {updateFields} = options || {};
                const result = isNotEmptyArray(updateFields) ? await post(`/v1/sys_users/${id}/patch`, {data, updateFields}) : await post(`/v1/sys_users/${id}/update`, data);
                return Promise.resolve(result);
            } catch (error) {
                return Promise.reject(error);
            }
        }, inSilentMode)
    }, [process, post]);

    return {
        createUserProcessing, createUserErrorMessage, createUser,
        searchUserProcessing, searchUserErrorMessage, searchUser, usersPagination,
        getUserProcessing, getUserErrorMessage, getUser, user,
        getProfileProcessing, getProfileErrorMessage, getProfile, profile,
        deleteUserProcessing, deleteUserErrorMessage, deleteUser,
        updateUserProcessing, updateUserErrorMessage, updateUser,
    };
};

useUser.propTypes = {
    processing: PropTypes.shape({
        createUser: PropTypes.bool,
        searchUser: PropTypes.bool,
        getUser: PropTypes.bool,
        getProfile: PropTypes.bool,
        deleteUser: PropTypes.bool,
        updateUser: PropTypes.bool,
    }),
    errorMessage: PropTypes.shape({
        createUser: PropTypes.string,
        searchUser: PropTypes.string,
        getUser: PropTypes.string,
        getProfile: PropTypes.string,
        deleteUser: PropTypes.string,
        updateUser: PropTypes.bool,
    }),
};

export default useUser;
