import React, {useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Col, Form, Nav, ProgressBar, Row} from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import UserInformationForm from './UserInformationForm';
import EmailVerifyForm from './EmailVerifyForm';
import { AuthWizardContext } from '@/context/Context';
import IconButton from '@/components/common/IconButton';
import WizardModal from './WizardModal';
import Flex from '@/components/common/Flex';
import { useAppContext } from '@/Main';
import AccountType from "@/components/authentication/default/wizard/AccountTypeForm";
import useAuth from "@/hooks/useAuth";
import IconAlert from "@/components/common/IconAlert";
import Lottie from "lottie-react";
import infiniteLoop from '@/assets/images/animated-icons/infinite-loop.json';
import loadingSquare from '@/assets/images/animated-icons/loading-square.json';
import {useSearchParams} from "react-router-dom";
import usePostCallback from "@/hooks/usePostCallback";


const WizardLayout = ({ variant, validation, progressBar }) => {
  const [searchParams] = useSearchParams();
  const { isRTL } = useAppContext();
  const { user, setUser, step, setStep, showLoading, setShowLoading, errorMessage, setErrorMessage, showErrorMessage, setShowErrorMessage, submit } = useContext(AuthWizardContext);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    setError,
    clearErrors
  } = useForm();

  const invitationCode = searchParams.get("invitation-code");

  const { register : registerAccount, verifyOtp, invitation, getInvitation } = useAuth();

  const [modal, setModal] = useState(false);

  const onSubmitData = async data => {
    switch (step) {
      case 3:
        const {companyUid} = user;
        if(companyUid) {
          await submit(async () => {
            const {verificationCode} = data;
            await verifyOtp(companyUid)({otp: verificationCode});
            setUser({...user, ...data, ...{ verified: true }});
          })
        }
        break;
      case 2:
        await submit(async () => {
          const result = await registerAccount({...user, ...data, ...{fullName: `${data.firstName} ${data.lastName}`}});
          setUser({...user, ...data, ...result});
          setStep(step + 1);
        });
        break;
      case 1:
        setUser({...user, ...data});
        setStep(step + 1);
        break;
      default:
        setUser({});
        setStep(1);
        break;
    }
  };

  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (step !== 3) {
      if (targetStep < step) {
        setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };
  
  useEffect( () => {
    //auto correct step if user data not match
    const {companyUid} = user;
    if (step > 2 && !companyUid) {
      setStep(2);
    }
  }, [user, step, setStep])

  // const called = useRef(false);
  // useEffect(() => {
  //   if (!called.current) {
  //     called.current = true;
  //     if(invitationCode){
  //       submit(async () => {
  //         await getInvitation(invitationCode);
  //       })
  //     }
  //   }
  // }, [invitationCode])

  usePostCallback([() => {
    if(invitationCode){
      submit(async () => {
        await getInvitation(invitationCode);
      })
    }
  }], [invitationCode])

  const navItems = [
    {
      icon: 'user',
      label: 'User Information'
    },
    {
      icon: 'star',
      label: 'Account Type'
    },
    {
      icon: 'thumbs-up',
      label: 'Verify Email'
    }
  ];
  return (
    <>
      <WizardModal modal={modal} setModal={setModal} />
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames('bg-body-tertiary', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}

        {showLoading && (
            <Row className="d-flex justify-content-center my-3">
              <Col lg={4} className="d-flex justify-content-center">
                <Lottie animationData={loadingSquare} loop={true}  style={{height: '72px', width: '72px'}} />
              </Col>
            </Row>
        )}

        {showErrorMessage && errorMessage && (
            <IconAlert className="mx-6" variant={'warning'} dismissible onClose={() => setShowErrorMessage(false)}>
              <p className="mb-0">{errorMessage}</p>
            </IconAlert>
        )}

        <Card.Body className="fw-normal px-md-6 py-4">
          {invitationCode && !invitation && (
              <div>{showLoading ? `Loading Invitation...` : `Invitation not found. Please contact to Administrator.`}</div>
          )}
          {((invitationCode && invitation) || (!invitationCode)) && (
              <>
                {step === 1 && (

                    <UserInformationForm
                        control={control}
                        register={register}
                        errors={errors}
                        setError={setError}
                        clearErrors={clearErrors}
                        setValue={setValue}
                        watch={watch}
                        invitation={invitation}
                    />
                )}
                {step === 2 && (
                    <AccountType
                        control={control}
                        register={register}
                        errors={errors}
                        setError={setError}
                        clearErrors={clearErrors}
                        setValue={setValue}
                        watch={watch}
                        invitation={invitation}
                    />
                )}
                {step === 3 && (
                    <EmailVerifyForm
                        control={control}
                        register={register}
                        errors={errors}
                        setError={setError}
                        clearErrors={clearErrors}
                        setValue={setValue}
                        watch={watch}
                        reset={reset}
                    />
                )}
              </>
          )}
        </Card.Body>
        {((invitationCode && invitation) || (!invitationCode)) && (
            <Card.Footer
                className={classNames('px-md-6 bg-body-tertiary', {
                  'd-none': step === 3,
                  ' d-flex': step < 3
                })}
            >
              <IconButton
                  variant="link"
                  icon={isRTL ? 'chevron-right' : 'chevron-left'}
                  iconAlign="left"
                  transform="down-1 shrink-4"
                  className={classNames('px-0 fw-semibold', {
                    'd-none': step === 1
                  })}
                  onClick={() => {
                    setStep(step - 1);
                  }}
              >
                Prev
              </IconButton>

              <IconButton
                  disabled={showLoading}
                  variant="primary"
                  className="ms-auto px-5"
                  type="submit"
                  icon={isRTL ? 'chevron-left' : 'chevron-right'}
                  iconAlign="right"
                  transform="down-1 shrink-4"
              >
                Next
              </IconButton>
            </Card.Footer>
        )}
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: index < 3 ? step > index : step > 2,
          active: step === index
        })}
        // onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs-10">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semibold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs-10 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
